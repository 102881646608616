'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types'
import { dashboard, views } from '../../actions/influencer';
import { setMetatags } from '../../actions/settings';
import { page_metas } from '../Helper';
import Alerts from '../Alerts';
import { Chart } from 'react-google-charts';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Well from 'react-bootstrap/lib/Well';
import Clearfix from 'react-bootstrap/lib/Clearfix';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';


class InfluencerDashboard extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.influencer_dashboard;
    this.state = {
      pageTitle: metatags.title,
      data: {},
      orders: {
        rows: [],
        columns: [
          {
            type: 'date',
            label: 'Date',
          },
          // {
          //   type: 'number',
          //   label: 'Amount',
          // },
          {
            type: 'number',
            label: 'Orders',
          },
          {
            type: 'string',
            role: 'tooltip',
            p: { 'html': true },
            span: { 'html': true }
          }
        ],
        options: {
          title: 'Total Orders',
          // subtitle: 'Subtitle',
          orientation: 'horizontal',
          hAxis: {
            title: 'Date',
            format: 'MMM dd, yy',
            gridlines: { color: 'none', count: 15 },
            slantedText: true,
            slantedTextAngle: 30
          },
          vAxis: {
            title: 'Totals Orders',
            minValue: 0,
            viewWindowMode: 'pretty',
            viewWindow: { min: 0 }
          },
          width: '100%',
          height: '400px',
          legend: 'none',// { position: 'top', maxLines: 3 },
          // focusTarget: 'category',
          tooltip: { isHtml: true },
        },
      },
      views: {
        rows: [],
        columns: [
          {
            type: 'date',
            label: 'Date',
          },
          {
            type: 'number',
            label: 'Views',
          },
          {
            type: 'string',
            role: 'tooltip',
            p: { 'html': true },
            span: { 'html': true }
          },
          {
            type: 'number',
            label: 'Visitors',
          },
          {
            type: 'string',
            role: 'tooltip',
            p: { 'html': true },
            span: { 'html': true }
          }
        ],
        options: {
          title: 'Page Views / Daily Visitors',
          // subtitle: 'Subtitle',
          orientation: 'horizontal',
          // crosshair: { orientation: 'horizontal' },
          hAxis: {
            title: 'Date',
            gridlines: { color: 'none', count: 15 },
            format: 'MMM dd, yy',
            slantedText: true,
            slantedTextAngle: 30
          },
          vAxis: {
            title: 'Views',
            minValue: 0,
            viewWindow: {
              min: 0
            }
          },
          width: '100%',
          height: '400px',
          legend: true,
          tooltip: { isHtml: true },
        },
      },
      /*visits: {
        rows: [],
        columns: [
          {
            type: 'date',
            label: 'Date',
          },
          {
            type: 'number',
            label: 'Daily Visits',
          },
          {
            type: 'string',
            role: 'tooltip',
            p: { 'html': true },
            span: { 'html': true }
          }
        ],
        formatters: [
          {
            type: 'DateFormat',
            column: 0,
            formatType: 'medium'
          }
        ],
        options: {
          title: 'Daily Visits',
          subtitle: 'Subtitle',
          orientation: 'horizontal',
          is3D: true,
          crosshair: { orientation: 'horizontal' },
          hAxis: {
            title: 'Date',
            gridlines: { color: 'none', count: 15 },
            format: 'MMM dd, yy'
          },
          vAxis: {
            title: 'Vistors',
            minValue: 0,
            viewWindow: {
              min: 0
            }
          },
          width: '100%',
          height: '400px',
          legend: 'none',
          tooltip: { isHtml: true },
        },
      },*/
      datepicker: {
        options: {
          ranges: {
            'Today': [Moment(), Moment()],
            'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
            'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
            'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
            'This Month': [Moment().startOf('month'), Moment().endOf('month')],
            'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')]
          },
          // onEvent: this.handleDateChange(this)
          startDate: Moment().subtract(29, 'days'),
          endDate: Moment(),
          maxDate: Moment(),
        },
        from: Moment().subtract(29, 'days').utcOffset(-240).format('MM/DD/YYYY'),
        to: Moment().utcOffset(-240).format('MM/DD/YYYY')
      }
    };
    this.props.setMetatags(metatags);
  }

  componentDidMount() {
    this.props.dashboard(this.props.user.influencer.id, this.state.datepicker.from, this.state.datepicker.to, this.props.token);
    // this.props.views(this.props.user.influencer.id, this.state.datepicker.from, this.state.datepicker.to, this.props.token);
  }
  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      let newState = {};
      newState.alerts = props.alerts;
      newState.data = Object.assign({}, {
        todayPageViews: props.states.todayPageViews,
        totalOrders: props.states.totalOrders,
        totalCommission: props.states.totalCommission,
      });
      newState.orders = Object.assign({}, state.orders, { rows: props.states.points });
      newState.views = Object.assign({}, state.views, { rows: props.states.daily_views });
      newState.loading = props.loading;
      return newState;
    }
    return null;
  }
  /*componentWillReceiveProps(nextProps) {
    if (this.props.loading != nextProps.loading && !nextProps.loading) {
      const current_state = this.state;
      this.setState({
        alerts: nextProps.alerts,
        data: Object.assign({}, {
          todayPageViews: nextProps.states.todayPageViews,
          totalOrders: nextProps.states.totalOrders,
          totalCommission: nextProps.states.totalCommission,
        }),
        orders: Object.assign({}, current_state.orders, { rows: nextProps.states.points }),
        views: Object.assign({}, current_state.views, { rows: nextProps.states.daily_views }),
        loading: nextProps.loading
      });
    }

  }*/

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  handleDateChange(event, picker) {
    switch (event.type) {
      case 'apply':
        const datepicker = Object.assign({}, this.state.datepicker, {
          from: picker.startDate.format('MM/DD/YYYY'),
          to: picker.endDate.format('MM/DD/YYYY')
        });
        this.setState({ datepicker: datepicker });
        this.props.dashboard(this.props.user.influencer.id, this.state.datepicker.from, this.state.datepicker.to, this.props.token);
        break;
    }

  }
  showWallItem(value, label, icon) {
    const item_icon = `fa fa-2x ${icon}`;
    return (
      <Col xs={12} sm={4} md={4}>
        <Well className="dash-wall">
          <div className="dash-text">
            <div className="dash-value">{value}</div>
            <div className="dash-label">{label}</div>
          </div>
          <div className="dash-icon">
            <i className={item_icon} aria-hidden="true" />
          </div>
        </Well>
      </Col>
    )
  }

  render() {
    const orders_data = this.state.orders.rows && this.state.orders.rows.length;
    const orders_tootltip = orders_data ? this.state.orders.rows.map((x, i) => {
      let d = x[0].split('/');
      const month = parseInt(d[0]) - 1;
      const day = parseInt(d[1]);
      const year = parseInt(d[2]);
      const date = new Date(year, month, day);
      const formatedDate = Moment(date).format('MMM DD, YYYY');
      const amount = toCurrency(x[1], 2);
      const tooltip = `<ul class="tootltip-wrapper">
        <li><span class="tootltip-label">Date: </span><span>${formatedDate}</span></li>
        <li><span class="tootltip-label">Orders: </span><span>${x[2]}</span></li>
      </ul>`;
      //        <li><span class="tootltip-label">Amount: </span><span>${amount}</span></li>
      return [date, x[2], tooltip]
    }) : '';
    const views_data = this.state.views.rows && this.state.views.rows.length;
    const views_tootltip = views_data ? this.state.views.rows.map((x, i) => {
      let d = x[0].split('/');
      const month = parseInt(d[0]) - 1;
      const day = parseInt(d[1]);
      const year = parseInt(d[2]);
      const date = new Date(year, month, day);
      const formatedDate = Moment(date).format('MMM DD, YYYY');
      const tooltip = `<ul class="tootltip-wrapper">
        <li><span class="tootltip-label">Date: </span><span>${formatedDate}</span></li>
        <li><span class="tootltip-label">Views: </span><span>${x[1]}</span></li>
      </ul>`;
      const tooltip1 = `<ul class="tootltip-wrapper">
        <li><span class="tootltip-label">Date: </span><span>${formatedDate}</span></li>
        <li><span class="tootltip-label">Visitors: </span><span>${x[2]}</span></li>
      </ul>`;
      return [date, x[1], tooltip, x[2], tooltip1]
    }) : '';

    // const vistors_tootltip = views_data ? this.state.views.rows.map((x, i) => {
    //   let d = x[0].split('/');
    //   const month = parseInt(d[0]) - 1;
    //   const day = parseInt(d[1]);
    //   const year = parseInt(d[2]);
    //   const date = new Date(year, month, day);
    //   const formatedDate = Moment(date).format('MMM DD, YYYY');
    //   const tooltip = `<ul class="tootltip-wrapper">
    //     <li><span class="tootltip-label">Date: </span><span>${formatedDate}</span></li>
    //     <li><span class="tootltip-label">Vistors: </span><span>${x[2]}</span></li>
    //   </ul>`;
    //   return [date, x[2], tooltip]
    // }) : '';
    const daterange = `${this.state.datepicker.from} - ${this.state.datepicker.to} `
    return (
      <div id="influencer-dashboard" >
        <PageHeader className="">{this.state.pageTitle}</PageHeader>
        <Alerts alerts={this.props.alerts} />
        <Row>

          {this.showWallItem(orders_data ? this.state.data.todayPageViews : 0, 'TODAY VISITS', 'fa-pie-chart')}
          {this.showWallItem(orders_data ? this.state.data.totalOrders : 0, 'ORDERS', 'fa-shopping-cart')}
          {this.showWallItem(orders_data ? toCurrency(this.state.data.totalCommission, 2) : 0, 'COMMISION', 'fa-money')}
          <Clearfix />
          <Col xs={12} sm={5} md={3} className="pull-right">
            <DateRangePicker {...this.state.datepicker.options} onEvent={this.handleDateChange.bind(this)} >
              <div id="dashboard-report-range" data-display-range="0" className="pull-right">
                <InputGroup className="pull-right">
                  <FormControl
                    value={daterange}
                    type="text"
                    readOnly aria-describedby="basic-addon2"
                  />
                  <InputGroup.Addon id="basic-addon2">&nbsp;
                    <img alt={"calendar"} width={20} height={20} src="/default/icon-calendar.svg" />
                  </InputGroup.Addon>
                </InputGroup>
              </div>
            </DateRangePicker>
          </Col>
          <Clearfix />
          <Col xs={12} sm={12} md={12}>
            {orders_data ?
              <Chart
                options={this.state.orders.options}
                rows={orders_tootltip}
                columns={this.state.orders.columns}
                // formatters={this.state.orders.formatters}
                chartType='BarChart'
                graph_id="OrdersChart"
                width="100%"
                height="400px"
                legend_toggle
              /> : ''}
          </Col>
          <Col xs={12} sm={12} md={12}>
            {views_data ?
              <Chart
                options={this.state.views.options}
                rows={views_tootltip}
                columns={this.state.views.columns}
                // formatters={this.state.views.formatters}
                chartType='BarChart'
                graph_id="PageViewsChart"
                width="100%"
                height="400px"
                legend_toggle
              /> : ''}
          </Col>
          {/* <Col xs={12} sm={12} md={12}>
            {views_data ?
              <Chart
                options={this.state.visits.options}
                rows={vistors_tootltip}
                columns={this.state.visits.columns}
                formatters={this.state.visits.formatters}
                chartType='BarChart'
                graph_id="VisitorsViewsChart"
                width="100%"
                height="400px"
                legend_toggle
              /> : ''}
          </Col> */}
          <Clearfix />
        </Row>
      </div>
    );
  }
}

InfluencerDashboard.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    alerts: state.alerts,
    states: state.influencer.states,
    loading: state.settings.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dashboard: (id, from, to, token) => {
      dispatch(dashboard(id, from, to, token));
    },
    views: (id, from, to, token) => {
      dispatch(views(id, from, to, token));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencerDashboard));

const toCurrency = (value, fixed = 2) => {
  return '$' + `${(value).toFixed(fixed)}`;
};

class CustomTooltip extends React.Component {

  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      return (
        <div className="custom-tooltip-wrapper">
          <div className="label"><span className="label">Date : </span>{`${label}`}</div>
          <div className="label"><span className="label">Total Amount :  </span>{`${toPercent(payload[0].value)}`}</div>
          <div className="label"><span className="label">Total Orders :  </span>{`${payload[0].payload.count}`}</div>
        </div>
      );
    }
    return null;
  }
};

CustomTooltip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string,
}
