'use strict';
const initialState = {
  loading: 1,
  sizes: {}
};

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'PRODUCT_SIZES_LOADING':
      return Object.assign({}, state, {
        loading: 1,
      });
    case 'PRODUCT_SIZES_SUCCESS':
      return Object.assign({}, state, {
        loading: action.loading,
        sizes: action.sizes,
      });
    default:
      return initialState;
  }
}
