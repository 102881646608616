'use strict';
const initialState = {
  states: {}
};

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'INFLUENCER_LOADING':
      return Object.assign({}, state, {
        loading: 1,
      });
    case 'INFLUENCER_LOADED_SUCCESS':
      return Object.assign({}, state, {
        item: action.item,
        metatags: action.metatags,
      });
    case 'INFLUENCER_DASHBOARD_LOADED_SUCCESS':
      return Object.assign({}, state, {
        states: action.states,
        metatags: action.metatags,
      });
    case 'INFLUENCER_UPDATE_SUCCESS':
      if (state.id && action.influencer.id && action.influencer.id.toString() == state.id.toString()) {
        return Object.assign({}, state, {
          influencer_logo: action.influencer.influencer_logo,
          socials: action.influencer.socials,
          settings: action.influencer.settings,
          subdomain: action.influencer.subdomain,
          slogon: action.influencer.slogon,
          slug: action.influencer.slug,
          website: action.influencer.website,
          status: action.influencer.status
        });
      }
      return state;
    case 'INFLUENCER_DASHBOARD_LOAD_FAILURE':
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
