'use strict';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import Redirect from 'react-router-dom/Redirect';
import { connect } from 'react-redux'
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import {  ReCaptcha } from 'react-recaptcha-v3';
import PropTypes from 'prop-types';
import { setMetatags } from '../../actions/settings';
import { signup } from '../../actions/auth';
import { facebookLogin, twitterLogin, googleLogin } from '../../actions/oauth';
import Alerts from '../Alerts';
import { page_metas, FormGroupHelper, getReCaptchaSiteKey,loadReCaptcha, LoginSignupNavigation} from '../Helper';


class Signup extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.signup;
    this.state = {
      pageTitle: metatags.title,
      username: '',
      email: '',
      password: '',
      gtoken: '',
      siteKey: getReCaptchaSiteKey(),
      action: 'signup'
    };
    this.props.setMetatags(metatags);
  }
  componentDidMount() {
    loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      this.props.clearAlerts();
      const route_state = this.context.router.route.location.state;
      const url = (route_state && route_state.referrer) ? this.context.router.route.location.state.referrer : '/';
      this.context.router.history.push(url);
    }
  }

  // componentWillUnmount() {
  //   this.props.clearAlerts();
  // }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  verifyCallback(recaptchaToken) {
    this.setState({ gtoken: recaptchaToken });
  }

  updateToken() {
    this.recaptcha.execute();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.updateToken();
    this.props.signup(this.state.username, this.state.email, this.state.password, this.state.gtoken, this.props.influencer);
  }

  handleFacebook() {
    this.props.facebookLogin(this.props.influencer);
  }

  handleTwitter() {
    this.props.twitterLogin(this.props.influencer);
  }

  handleGoogle() {
    this.props.googleLogin(this.props.influencer);
  }

  render() {
    if (this.props.token) {
      return (
        <Redirect to="/" />
      )
    }

    return (
      <Grid className="login-container clear-paddings" id="login-signup-tabs">
        {LoginSignupNavigation(this.state.action)}
        <Row className="tab-content">
        <Col lg={12}>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            {/* <PageHeader>{this.state.pageTitle}</PageHeader> */}
            <Alerts alerts={this.props.alerts} />
            <div className="login-signup-form">
              <FormGroupHelper
                id='username'
                name="username"
                type="text"
                label="Username"
                placeholder="Username"
                value={this.state.username}
                onChange={this.handleChange.bind(this)}
              />
              <FormGroupHelper
                id='email'
                name="email"
                type="email"
                label="Email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
              />
              <FormGroupHelper
                id='password'
                name="password"
                type="password"
                label="Password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange.bind(this)}
              />
              <ReCaptcha
                ref={ref => this.recaptcha = ref}
                sitekey={this.state.siteKey}
                action={this.state.action}
                verifyCallback={this.verifyCallback.bind(this)}
              />
              <FormGroup>
                <small className="text-muted">By signing up, you agree to the <Link to="/terms">Terms of Use</Link>.</small>
              </FormGroup>
              </div>
            <Button type="submit" bsStyle="snkrly" className="btn-snkrly-dark btn-block">Sign Up</Button>
          </Form>
          {/* <SocialLoginSignup fbevent={this.handleFacebook.bind(this)} twevent={this.handleTwitter.bind(this)} /> */}
          <HelpBlock className="text-center">
            Already have an account? <Link to="/login"><strong>Log in</strong></Link>
          </HelpBlock>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Signup.contextTypes = {
  router: PropTypes.object.isRequired
}
Signup.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    isAuthenticated: state.auth.token ? true : false,
    loading: state.settings.loading,
    influencer: state.influencer,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    signup: (username, email, password, gtoken, influencer) => {
      dispatch(signup(username, email, password, gtoken, influencer));
    },
    facebookLogin: (influencer) => {
      dispatch(facebookLogin(influencer));
    },
    twitterLogin: (influencer) => {
      dispatch(twitterLogin(influencer));
    },
    googleLogin: () => {
      dispatch(googleLogin(influencer));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
