'use strict';
import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import Redirect from 'react-router-dom/Redirect';

import App from './components/App';
import Home from './components/Home';
import NotFound from './components/NotFound';
import { Reset, Forgot, Profile, Signup, Login } from './components/Account';
import { Past, Upcoming, ReleaseView, Shop, Search, UnderRetail, Latest, Category, Tag, ReleaseDates, Popular } from './components/Releases';
import { OrderList, OrderView, Checkout, Complete } from './components/Order';
import { InfluencerSignup, Settings, InfluencerDashboard } from './components/Influencer';
import Pages from './components/Pages';
import {record404} from './actions/settings';
import { KicksOnFireRedirects }  from './redirects'

const RemoveTrailingSlash = ({location}) => {
        const {pathname} = location;
              
        if (pathname.substr(-1) === '/') {
          return <Redirect to={pathname.substr(0, pathname.length - 1)} />;
        } else {
          return null;
        }
      };
      
export const getRoutes = (store) => {
        const influencer = store.getState().influencer;

        const ensureAuthenticated = (Comp, props, url = '') => {
                if (!store.getState().auth.token) {
                        return <Redirect to={{
                                pathname: url ? url : '/login',
                                state: { referrer: props.location.pathname }
                        }} />;
                }
                return <Comp {...props} />
        };

        const  redirectTo404 = (Comp, props, url = '') => {
                if(props.location.pathname.replaceAll('/', '') !== 'error404'){
                        let url = props.location.pathname.split('/');
                        record404(props.location.pathname, props.influencer);
                        const string = url[url.length - 1].replaceAll('-', '+');
                        // window.location.href = '/error/404?keyword='+string;
                        return <Redirect to={{
                                pathname: '/error/404?keyword='+string,
                                state: { referrer: props.location.pathname }
                        }} />;
                }
        };

        const ensureIsAdminAuthenticated = (Comp, props, url = '') => {
                if (!store.getState().auth.token) {
                        return <Redirect to={{
                                pathname: url ? url : '/security/login',
                                state: { referrer: props.location.pathname }
                        }} />;
                }
                return <Comp {...props} />
        };
        const ensureInfluencer = (Comp, props, url = '') => {
                if (!(store.getState().auth.token && store.getState().auth.user.influencer)) {
                        return <Redirect to={{
                                pathname: '/login',
                                state: { referrer: props.location.pathname }
                        }} />;
                }
                return <Comp {...props} />
        };
        const ensureInfluencerOwner = (Comp, props, url = '') => {
                if (!store.getState().auth.token) {
                        return <Redirect to={{
                                pathname: '/login',
                                state: { referrer: props.location.pathname }
                        }} />;
                }
                if (!store.getState().auth.user.influencer || store.getState().influencer.id.toString() != store.getState().auth.user.influencer.id.toString()) {
                        return <NotFound {...props} />;
                }

                return <Comp {...props} />
        };
        const skipIfAuthenticated = (Comp, props, url = '/') => {
                if (store.getState().auth.token) {
                        return <Redirect to={url} />;
                }
                return <Comp {...props} />

        };

        let routers = [{
                component: Pages,
                path: '/faq',
                exact: true,
        }, 
        // {
        //         component: Pages,
        //         path: '/about',
        //         exact: true,
        // }, 
        {
                component: Pages,
                path: '/privacy',
                exact: true,
        }, 
        {
                component: Pages,
                path: '/terms',
                exact: true,
        },];

        if (!influencer.id || !influencer.status) {
                routers.push({
                        component: Home,
                        path: '/',
                        exact: true,
                });
                routers.push({
                        component: InfluencerSignup,
                        path: '/invite',
                });
                /*routers.push({
                        component: InfluencerSignup,
                        path: '/security',
                        collback: ensureAuthenticated,
                        url: '/security/login'
                });
                routers.push({
                        component: InfluencerSignup,
                        path: '/security/influencer/:id',
                        collback: ensureAuthenticated,
                        url: '/security/login'
                });*/

        } else {
                routers.push({
                        component: Latest,
                        path: '/',
                        exact: true,
                });
                routers.push({
                        component: Past,
                        path: '/past',
                });
                routers.push({
                        component: Upcoming,
                        path: '/upcoming',
                });
                routers.push({
                        component: Shop,
                        path: '/shop',
                });                
                routers.push({
                        component: Search,
                        path: '/search',
                });
                routers.push({
                        component: UnderRetail,
                        path: '/under-retail',
                });
                routers.push({
                        component: UnderRetail,
                        path: '/sale',
                });                
                routers.push({
                        component: Login,
                        path: '/login',
                        collback: skipIfAuthenticated,
                });
                // routers.push({
                //         component: LoginSignup,
                //         path: '/loginsignup',
                //         collback: skipIfAuthenticated,
                // });                
                routers.push({
                        component: Signup,
                        path: '/signup',
                        collback: skipIfAuthenticated,
                });
                routers.push({
                        component: Forgot,
                        path: '/forgot',
                        collback: skipIfAuthenticated,
                });
                routers.push({
                        component: Reset,
                        path: '/reset/:token',
                        collback: skipIfAuthenticated,
                });
                routers.push({
                        component: Settings,
                        path: '/settings',
                        collback: ensureInfluencerOwner,
                });
                routers.push({
                        component: InfluencerDashboard,
                        path: '/dashboard',
                        collback: ensureInfluencerOwner,
                });
                routers.push({
                        component: Profile,
                        path: '/account',
                        collback: ensureAuthenticated,
                });
                routers.push({
                        component: Checkout,
                        path: '/checkout',
                        collback: ensureAuthenticated,
                });
                routers.push({
                        component: OrderList,
                        path: '/orders',
                        collback: ensureAuthenticated,
                });
                routers.push({
                        component: OrderView,
                        path: '/order/:orderId',
                        exact: true,
                        collback: ensureAuthenticated,
                });
                routers.push({
                        component: Complete,
                        path: '/order/:orderId/complete',
                        collback: ensureAuthenticated,
                });
                routers.push({
                        component: NotFound,
                        path: '/error/404',
                });
                routers.push({
                        component: Category,
                        path: '/category/:slug',
                        exact: true,
                });               
                routers.push({
                        component: ReleaseDates,
                        path: '/:slug-release-dates/:eventType?',
                });
                routers.push({
                        component: Tag,
                        path: '/tag/:slug',
                        exact: true,
                }); 
                routers.push({
                        component: Popular,
                        path: '/popular/:slug',
                        exact: true,
                        // location: '/popular'
                });
                // routers.push({
                //         component: ReleaseView,
                //         path: '/release/:slug',
                // });
                routers.push({
                        component: ReleaseView,
                        path: '/:slug',
                        exact: true,
                });
        }

        routers.push({
                component: NotFound,
                path: '*',
                status: 404,
                collback: redirectTo404
        });

        const route = routers.map((route, index) => {
                const isexact = route.exact ? true : false;
                const redirect = route.redirect ? route.redirect : '';
                return (
                        <Route key={index} path={route.path} exact={isexact} render={(props) => (
                                route.collback ? route.collback(route.component, props, redirect)
                                        : <route.component {...props} />


                        )} />
                )
        });
        

        // const kofRedirect = KicksOnFireRedirects().map((item, index)=>{
        //         const key = index +1;
        //         return (<Redirect key={key} from={item.from} to={item.to} />);
        // });
        return (
                <App>
                        <Switch>
                                {/* <Redirect from="/app/:path*(/{0,1})" to={"/:path*"} /> */}
                                {/* <Redirect from="/app/*" to={"/*"} />  */}
                                {/* <Redirect from="/category/nike/nike-basketball/nike-foamposite" to={"/tag/nike-air-foamposite"} />
                                <Redirect from="/air-jordans" to={"/air-jordan-release-dates"} /> */}
                                {KicksOnFireRedirects()}
                                {/* <Redirect from="/*(\/+)(\?)*" to={'/*'} /> */}
                                {/* <Redirect from="/*((\/\?)+)" to={'/*?*'} /> */}
                                {/* <Redirect from="/:path*(/+)" to={'/:path*'} /> */}
                                {/* <Redirect from="/release/:slug" to={"/:slug"} /> */}
                                {route}
                        </Switch>
                </App>
        );
}
