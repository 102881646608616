'use strict';
import React, { Component } from 'react';

class ReadMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }
    toggleReadMore() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        let description =  '';
        if(this.props.description.length>0){
            let desc = this.props.description.split('\n\r');
            if(desc.length>1){
                const fist_part = desc[0];
                const second_part = desc.slice(1);
                const readMoreClasses = 'desc-read-more'+  (this.state.isOpen?' open':'')
                description = (<div className='description'>
                                    <div dangerouslySetInnerHTML={{__html: '<p>' + fist_part+'</p>'}} />
                                    <div className={readMoreClasses} dangerouslySetInnerHTML={{__html: '<p>' + second_part.join('</p><p>')+'</p>'}} />
                                    <span onClick={() => this.toggleReadMore()} className="read-more">{this.state.isOpen?"read less":"read more"}</span>
                                </div>);
            }else{
                description = <div className='description' dangerouslySetInnerHTML={{__html: '<p>' + desc.join('</p><p>')+'</p>'}} />
            }
        }
        return description;
    }
};

export default ReadMore;