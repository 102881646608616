'use strict';
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from './constants';
require('es6-promise').polyfill();

export const countries = (token) => {
    return (dispatch) => {
        dispatch({
            type: 'COUNTRIES_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/settings/countries',
            data: {}
        });
        return axios(options).then((response) => {
            const json = response.data;
            if (json.success) {
                dispatch({
                    type: 'COUNTRIES_SUCCESS',
                    items: json.data.items,
                });
            } else {
                dispatch({
                    type: 'COUNTRIES_FAILURE',
                    alerts: json.errors,
                });
            }
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const provinces = (country, token) => {
    return (dispatch) => {
        dispatch({
            type: 'PROVINCES_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/settings/provinces',
            data: { country: parseInt(country) }
        });
        return axios(options).then((response) => {
            const json = response.data;
            let dispatch_json = {};
            if (json.success) {
                dispatch_json = {
                    type: 'PROVINCES_SUCCESS',
                    items: json.data.items,
                }
            } else {
                dispatch_json = {
                    type: 'PROVINCES_FAILURE',
                    alerts: json.errors,
                }
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    }
}
export const categories = (country, token) => {
    return (dispatch) => {
        dispatch({
            type: 'CATEGORY_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/settings/categories',
            data: { country: parseInt(country) }
        });
        return axios(options).then((response) => {
            const json = response.data;
            let dispatch_json = {};
            if (json.success) {
                dispatch_json = {
                    type: 'CATEGORY_SUCCESS',
                    items: json.data.items,
                }
            } else {
                dispatch_json = {
                    type: 'CATEGORY_FAILURE',
                    alerts: json.errors,
                }
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    }
}

export const estimate_delivery = (nid, location = {}) => {
    return (dispatch) => {
        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/settings/estimate_delivery',
            data: { nid: nid, location: location }
        });
        return axios(options).then((response) => {
            const json = response.data;
            let dispatch_json = {};
            if (json.success) {
                dispatch_json = {
                    type: 'ESTIMATE_DELIVERY_SUCCESS',
                    estimated_delivery: json.success ? json.data.estimated_delivery : '',
                }
            } else {
                dispatch_json = {
                    type: 'ESTIMATE_DELIVERY_FAILURE',
                    alerts: json.errors,
                }
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const estimate_tax = (item, address, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'ESTIMATE_TAX_LOADING',
            tax_loading: 1
        });
        const req_body = {
            item: { nid: parseInt(item.nid), oid: parseInt(item.oid), qty: parseInt(item.qty) },
            influencer: influencer,
        };
        const postBody = Object.assign({}, req_body, address);
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/settings/taxes',
            data: postBody
        });
        return axios(options).then((response) => {
            const json = response.data;
            if (json.success) {
                dispatch({
                    type: 'ESTIMATE_TAX_SUCCESS',
                    estimated_tax: json.data,
                    tax_loading: 0,
                });
            } else {
                dispatch({
                    type: 'ESTIMATE_TAX_FAILURE',
                    alerts: Array.isArray(json.errors) && json.errors.length ? json.errors : (json.msg.length > 0 ? [{ msg: json.msg }] : [])
                });
            }
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const topInfluencers = () => {
    return (dispatch) => {
        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/settings/top_influencers',
            method: 'get'
        });
        return axios(options).then((response) => {
            const json = response.data;
            let dispatch_json = {};
            if (json.success) {
                dispatch_json = {
                    type: 'TOP_INFLUENCERS_SUCCESS',
                    top_influencers: json.success ? json.result : [],
                }
            } else {
                dispatch_json = {
                    type: 'TOP_INFLUENCERS_FAILURE',
                    alerts: json.errors,
                }
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const submitContactForm = (name, email, message) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/contact',
            data: {
                name: name,
                email: email,
                message: message
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'CONTACT_FORM_SUCCESS',
                    alerts: [json]
                };
            } else {
                dispatch_json = {
                    type: 'CONTACT_FORM_FAILURE',
                    alerts: Array.isArray(json) ? json : [json]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const setMetatags = (metatags) => {
    return (dispatch) => {
        dispatch({
            type: 'METATAGS_SUCCESS',
            metatags: metatags,
        });
    };
}

export const record404 = (url, influencer, token = null) => {
    return async (dispatch) => {
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer),
            url: '/settings/record404',
            data: { url: url }
        });
        return await axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'URL_404_RECORD_SUCCESS',
                };
            } else {
                dispatch_json = {
                    type: 'URL_404_RECORD_FAILURE',
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}