'use strict';
import cookie from 'react-cookie';
import moment from 'moment';
const initialState = {
  item: {},
  loading: 0,
};

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, { hydrated: true });
  }
  switch (action.type) {
    case 'CART_ADD_LOADING':
      return Object.assign({}, state, {
        loading: 1,
      });
    case 'CART_ADD_SUCCESS':
    case 'CART_ITEM_QTY_UPDATE':
      state = Object.assign({}, initialState, { hydrated: true });
      state.item = action.item;
      state.item.qty = action.qty;
      state.loading = action.loading;
      cookie.save('cart', JSON.stringify(state), {
        path: '/',
        expires: moment().add(1, 'week').toDate(),
        sameSite: 'lax',
        // httpOnly: true,
        // secure: true
      });
      return state;
    case 'CART_REMOVE_SUCCESS':
      state = Object.assign({}, initialState);
      state.loading = action.loading;
      cookie.save('cart', JSON.stringify(state), {
        path: '/',
        expires: moment().add(1, 'week').toDate(),
        sameSite: 'lax',
        // httpOnly: true,
        // secure: true
      });
      window.gtag('event', 'remove_from_cart', {
        'event_label': action.item.title,
      });
      return state;
    case 'CART_EMPTY_SUCCESS':
    case 'CHECKOUT_COMEPLETE':
    case 'LOGOUT_SUCCESS':
      cookie.remove('cart');
      return Object.assign(initialState, { hydrated: true });
    default:
      return state;
  }
}
