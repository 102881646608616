'use strict';
import React from 'react';

export const JsonLD = ({ jsonData, type = '' }) => {
    switch(type){
        case 'product':
            break;
        case 'productlist':
            break;    
    }
    return (<script type="application/ld+json"  dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonData) }}></script>)
}

export const ProductJDJSON = (item = {}) => {
    if(Object.keys(item).length > 0){
        const images = [httpCorrectUrl(item.master_image), ...item.images];
        let snippet = Object.assign({}, default_snippet, {
            name: item.title,
            mpn: style_code,
            sku: 'R-' + item.id,
            productID: 'R-' + item.id,
            url: url,
            // description: item.description.trim().length>0?item.description.trim():item.title,
            image: images
        });
        let prod_description = '';
        if(item.description.trim().length>0){
            prod_description = item.description.trim();
        }else{
            prod_description = item.title;
            if (item.style) {
                prod_description += item.style + '. ';
            }
            if (item.style_code) {
                prod_description += item.style_code;
            }                
        }
        snippet.description = prod_description;
        snippet.additionalProperty = []
        if (item.style) {
            let prop_colorway = {};
            snippet.color = item.style;
            prop_colorway["@type"] = 'PropertyValue';
            prop_colorway.name = 'Colorway';
            prop_colorway.value = item.style;
            snippet.additionalProperty.push(prop_colorway);
        }
        if(item.style_code){
            let prop_style_code = {};
            prop_style_code["@type"] = 'PropertyValue';
            prop_style_code.name = 'Style Code';
            prop_style_code.value = item.style_code;
            snippet.additionalProperty.push(prop_style_code);
        }
        if(item.price != 'N/A' && item.price > 0){
            let prop_retail_price = {};
            prop_retail_price["@type"] = 'PropertyValue';
            prop_retail_price.name = 'Retail Price';
            prop_retail_price.value = '$' + parseInt(item.price);
            snippet.additionalProperty.push(prop_retail_price);
        }
        if(item.event_date != null && item.event_date != undefined){
            snippet.releaseDate = dateFormat({value: item.event_date, format: "MMM DD, YYYY" })
        }

        let pixel_json = {
            content_name: item.title,
            currency: 'USD',
            content_type: 'product',
        }
        if (item.brand && item.brand.name) {
            pixel_json.content_category = item.brand.name;
            snippet.brand = {};
            snippet.brand["@type"] = "Brand";
            let brandName = item.brand.name;
            if (item.title.match(/fear of god/i)) {
                brandName = 'Fear Of God';
            }
            snippet.brand.name = brandName;
        }
        snippet.category=[ 
            "Shoes",
            "Sneakers",
            "Athletic",
        ];
        let min_value = 1000000;
        let max_value = 0;
        if (props.sizes.length > 0) {
            snippet.offers.availability = "https://schema.org/InStock";
            snippet.offers.offerCount = props.sizes.length;

            snippet.offers.offers = [];
            props.sizes.forEach((element, i) => {
                let g = 'M';
                let title_gender = 'Men';
                switch (element.gender.toLowerCase()) {
                    case 'youth':
                        g = 'K';
                        title_gender = 'Kids';
                        break;
                    case 'men':
                        g = 'M';
                        title_gender = 'Men';
                        break;
                    case 'women':
                        g = 'F';
                        title_gender = 'Women';
                        break;
                }
                let sz = element.size.replace('.', '_');
                let product_price_convert = element.product_price.replace(/,/, "");
                let size_price_convert = element.size_price.replace(/,/, "");
                let id = style_code + '-' + sz;
                let price = parseFloat(parseFloat(product_price_convert) + parseFloat(size_price_convert));
                content_ids.push(id);
                gtag_items.push({
                    'id': id,
                    'list_position': ++i,
                    'google_business_vertical': 'retail'
                });

                contents.push({
                    id: id,
                    quantity: 1,
                    item_price: price
                });
                if (price < min_value) {
                    min_value = price;
                }
                if (price > max_value) {
                    max_value = price;
                }

                let format_price = price.toFixed(0);
                let productID_sku = 'R-' + item.id + "-" + g + "-" + sz;//element.google_id ? element.google_id : item.id + '-' + sz;
                let offer = Object.assign({}, default_offer, {
                    price: format_price.toLocaleString('en-US'),
                    sku: productID_sku,
                    productID: productID_sku,
                    name: item.title + ' - Size ' + element.size + ' ' + title_gender,
                });
                if (element.gtin && element.gtin.length > 0) {
                    let gtin_length = element.gtin.length;
                    if (gtin_length <= 8) {
                        offer.gtin8 = element.gtin.padStart(8, '0');
                    } else if (gtin_length <= 12) {
                        offer.gtin12 = element.gtin.padStart(12, '0');
                    } else if (gtin_length == 13) {
                        offer.gtin13 = element.gtin;
                    } else if (gtin_length == 14) {
                        offer.gtin14 = element.gtin;
                    }
                }
                snippet.offers.offers.push(offer);
            });
            const format_min_value = min_value.toFixed(0);
            const format_max_value = max_value.toFixed(0);
            snippet.offers.lowPrice = format_min_value.toLocaleString('en-US');
            snippet.offers.highPrice = format_max_value.toLocaleString('en-US');
        } else {
            min_value = (item.price !== 'N/A' && parseInt(item.price) !== NaN && typeof parseInt(item.price) === 'number') ? item.price : 0;
            snippet.offers.price = min_value;
            snippet.offers["@type"] = "Offer";
            snippet.offers.url = url;
        }
        // Shipping 
        snippet.offers.shippingDetails = {};
        snippet.offers.shippingDetails["@type"] = "OfferShippingDetails";
        snippet.offers.shippingDetails.shippingRate = {};
        snippet.offers.shippingDetails.shippingRate["@type"] = "MonetaryAmount";
        snippet.offers.shippingDetails.shippingRate.value = 0;
        snippet.offers.shippingDetails.shippingRate.currency = "USD";
        snippet.offers.shippingDetails.shippingDestination = [];
        let shippingDestinationUS = {};
        shippingDestinationUS["@type"] = "DefinedRegion";
        shippingDestinationUS.addressCountry =  "US";
        snippet.offers.shippingDetails.shippingDestination.push(shippingDestinationUS);
        // if(country == 'CA'){
        //     let shippingDestinationCA = {};
        //     shippingDestinationCA["@type"] = "DefinedRegion";
        //     shippingDestinationCA.value =  "CA";
        //     snippet.offers.shippingDetails.shippingDestination.push(shippingDestinationCA);
        // }            
        if (contents.length > 0) {
            pixel_json.contents = contents;
            pixel_json.content_ids = content_ids;
        }
        if (min_value > 0) {
            pixel_json.value = min_value;
            const ga_adwords = props.influencer.analytics.adwords ? props.influencer.analytics.adwords : '';
            if (ga_adwords) {
                // if(typeof global.window != undefined){
                    global.window.gtag('event', 'view_item', {
                    'send_to': ga_adwords,
                    'value': min_value,
                    'items': gtag_items,
                    'currency': 'USD',
                });
                // }
            }
        }
        ReactPixel.track('ViewContent', pixel_json);

        let description = '';
        if (item.style) {
            description += item.style + '. ';
        }
        if (item.style_code) {
            description += item.style_code;
        }
        const gallery_images = images.length > 0 ? images.map((image, index) => {
            return {
                original: httpCorrectUrl(image),
                originalAlt: item.title,
                originalTitle: item.title,
            }
        }) : {};
        const item = Object.assign({}, item, { desc: description, gallery_images: gallery_images });
        const wants = item.wants < 100 ? item.wants+100 : item.wants;
        const randnum = Math.random()*10+1;
        snippet.aggregateRating = {};
        snippet.aggregateRating.ratingValue = Math.round(((randnum * wants/100) * 4.8 + ((20-randnum) * wants/100) * 4.9 + ((80) * wants/100) * 5) / (wants)*100)/100;
        snippet.aggregateRating["@type"] = "AggregateRating";
        snippet.aggregateRating.reviewCount = wants;
        return {
            item: item,
            snippet: snippet
        }




        const itemsld = items.map((item, index)=>{
            const position = index + 1;
            return {
                
            }
        })
    }
}

export const ProductListJDJSON = (items = [], page = 1) => {
    if(items.length > 0){
        const itemsld = items.map((item, index)=>{
            const position = index + 1;
            return {
                
            }
        })
    }
}