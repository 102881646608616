'use strict';
import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

const settings = {
    type: 'bars',
    color: '#444',
    width: 64,
    height: 64,
    delay: 0
}
class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.loading !== undefined && props.loading !== state.loading) {
            return {
                loading: props.loading
            }
        }
        return null;
    }

    render() {
        const showhide = this.state.loading == 1 ? '' : 'hidden';
        return (
            <div id="loader" className={showhide}>
                <ReactLoading {...settings} />
            </div>
        )
    }

};

Loader.defaultProps = {
    loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.settings.loading,
    };
};

export default connect(mapStateToProps)(Loader);