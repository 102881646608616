'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2/lib/Scrollbars';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import { messages, messageSend } from '../../actions/order';
import { FormatDate } from '../Helper';
import PropTypes from 'prop-types';

class OrderMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: 'Order #' + this.props.orderId,
            message: ''
        }
        this.handleScrollUpdate = this.handleScrollUpdate.bind(this);
    }

    componentDidMount() {
        const orderId = this.props.orderId;
        this.props.getOrderMessages(orderId, this.props.influencer, this.props.token);
    }

    componentWillUnmount() {
        this.props.clearMessages();
        this.props.clearAlerts();
    }

    componentDidUpdate() {
        this.handleScrollUpdate();
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleScrollUpdate() {
        const { scrollbars } = this.refs;
        scrollbars.scrollToBottom();
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.message.trim().length != 0) {
            this.props.clearAlerts();
            this.props.sendMessage(this.props.orderId, this.state.subject.trim(), this.state.message.trim(), this.props.influencer, this.props.token);
            this.setState({ message: '' });
            this.handleScrollUpdate();
        }
        return false;
    }
    render() {
        let allMessages = [];
        if (this.props.messages.id) {
            allMessages.push({
                id: this.props.messages.id,
                created: this.props.messages.created,
                sender: this.props.messages.sender ? this.props.messages.sender : {},
                messageText: this.props.messages.messageText
            });
            if (this.props.messages.messages.length > 0) {
                allMessages.push(...this.props.messages.messages);
                allMessages = allMessages.reverse();
            }

        }

        const messageList = allMessages ? allMessages.map((message, index) => {
            let wrclass = 'order-message-item-wrapper msg-content-right';
            let message_owner = 'Agent:';
            if (message.sender && message.sender.platformUserId == this.props.user.uid) {
                wrclass = 'order-message-item-wrapper msg-content-left';
                message_owner = 'Me:';
            }
            return (
                <div key={message.id}>
                    <div className={wrclass}>
                        <div className="message-date"><div className='msg-author'>{message_owner}</div><FormatDate value={message.created} format="MM/DD/YYYY h:mm A" /></div>
                        <div className="message-body">{message.messageText}</div>
                    </div>
                    <div className="clearfix" />
                </div>
            )
        }) : '';

        return (
            <div id="order-messages-wrapper">
                <h3>Messages</h3>
                <Row>
                    <Col xs={12} sm={6} md={7} id="order-messages-container">
                        <Scrollbars
                            ref="scrollbars"
                            id="message-scroll-content"
                            autoHeight
                            autoHeightMin={100}
                            autoHeightMax={200}
                            hideTracksWhenNotNeeded={true}>
                            {messageList}
                        </Scrollbars>
                    </Col>
                    <Col xs={12} sm={6} md={5} id="order-send-message-wrapper">
                        {/* <Alerts alerts={this.props.alerts} /> */}
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup>
                                <FormControl
                                    componentClass="textarea"
                                    name="message"
                                    placeholder="Write your question about order here..."
                                    id="message"
                                    rows="4"
                                    value={this.state.message}
                                    onChange={this.handleChange.bind(this)} />

                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={4} md={4} className="pull-right">
                                        <Button type="submit" bsStyle="snkrly" block>Send</Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

OrderMessages.propTypes = {
    orderId: PropTypes.number.isRequired
}
const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        influencer: state.influencer,
        user: state.auth.user,
        loading: state.settings.loading,
        messages: state.order.messages
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOrderMessages: (orderId, influencer, token) => {
            dispatch(messages(orderId, influencer, token));
        },
        sendMessage: (orderId, subject, message, influencer, token) => {
            dispatch(messageSend(orderId, subject, message, influencer, token));
        },
        clearMessages: () => {
            dispatch({ type: 'CLEAR_ORDER_MESSAGES' });
        },
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderMessages);
