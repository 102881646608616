import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addToCart } from '../../actions/cart';
import withRouter from 'react-router-dom/withRouter';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';
import Button from 'react-bootstrap/lib/Button';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import Clearfix from 'react-bootstrap/lib/Clearfix';

import { estimate_delivery } from '../../actions/settings';
import ReactPixel from 'react-facebook-pixel';
import { FormatCurrency, shippingInfo } from '../Helper';

class BuyNow extends Component {
  constructor(props) {
    super(props);
    this.state = { item: {} };
  }

  handleChange(item, event) {
    this.props.clearEstimateDelivery();
    let element = $(event.target);
    if (!element.hasClass('size-item')) {
      element = element.parents('.size-item');
    }

    let selected_item = {};
    if (element.hasClass('selected')) {
      element.removeClass('selected');
      // document.getElementById('buy-now-btn').removeClass('btn-snkrly-dark');
    } else {
      $('.size-item').removeClass('selected');
      element.addClass('selected');
      // document.getElementById('buy-now-btn').addClass('btn-snkrly-dark');
      selected_item = item;
    }
    this.setState({ item: selected_item });
    this.props.clearAlerts();
    if (selected_item.nid) {
      // cookie.save('size', JSON.stringify(state), {
      //   path: '/',
      //   expires: moment().add(1, 'week').toDate(),
      //   sameSite: 'lax',
      //   // httpOnly: true,
      //   // secure: true
      // });
      this.props.estimateDelivery(selected_item.nid);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.item.hasOwnProperty('nid')) {
      let item = Object.assign({}, this.state.item, {
        release: {
          id: this.props.release.id,
          name: this.props.release.title,
          thumb_image: this.props.release.thumb_image,
          slug: this.props.release.uri,
          style_code: this.props.release.style_code,
          style: this.props.release.style,
          brand_name: this.props.release.brand.name,
        }
      });
      this.props.addToCart(item);

      const sz = item.size.replace('.', '_');
      const style_code = item.release.style_code
        .replace('-', '')
        .replace(' ', '')
        .toUpperCase()
        .trim();
      const id = style_code + '-' + sz;// + '-' + g;
      const price = parseFloat(item.total_price);
      const pixel_json = {
        content_name: item.release.name,
        content_category: item.release.brand_name,
        currency: 'USD',
        content_type: 'product',
        contents: [{
          id: id,
          quantity: 1,
          item_price: price
        }],
        content_ids: [id],
        value: price
      }
      ReactPixel.track('AddToCart', pixel_json);
      this.props.history.push('/checkout');
    } else {
      this.props.selectSize();
    }
  }

  componentWillUnmount() {
    this.props.clearAlerts();
    this.props.clearEstimateDelivery();
  }

  decorateSizes(sizes) {
    return sizes.map((item, i) => {
      const product_price_convert = item.product_price.replace(/,/, "");
      const size_price_convert = item.size_price.replace(/,/, "");
      const price = parseFloat(parseFloat(product_price_convert) + parseFloat(size_price_convert)).toFixed(0);
      const key = item.nid + '-' + item.oid + '-' + i;
      return (
        <Col xs={3} className="clear-paddings" onClick={this.handleChange.bind(this, item)} id={key} key={key}>
          <div className="size-item">
            <div className="size-value">{item.size}</div>
            <div className="price-value"><FormatCurrency value={price} maximumFractionDigits={0}  minimumFractionDigits={0} round="ceil" /></div>
          </div>
        </Col>
      )
    });
  }

  render() {
    if (!(this.props.sizes.length > 0)) {
      // const isUpcoming = isPastOrUpcoming(this.props.release.event_date);
      // const message = isUpcoming? <div id="release-date" className='btn btn-snkrly'><b>Release Date: </b>{ dateFormat({value:this.props.release.event_date, format: "MMM DD, YYYY" })}</div> : '';//<div className="out-of-stock">{"Not in Stock"}</div>
      const message="";
      return (
        <>{message}</>
      );
    }
    let sizes = new Array;

    this.props.sizes.map((size, index) => {
      switch (size.gender.toLowerCase()) {
        case 'men':
          if (!sizes.hasOwnProperty('men')) {
            sizes['men'] = new Array;
          }
          sizes['men'].push(size);
          break;
        case 'women':
          if (!sizes.hasOwnProperty('women')) {
            sizes['women'] = new Array;
          }
          sizes['women'].push(size);
          break;
        case 'kids':
        case 'youth':
          if (!sizes.hasOwnProperty('kids')) {
            sizes['kids'] = new Array;
          }
          sizes['kids'].push(size);
          break;
      }
    });
    let men_sizes = null;
    let active = 0;
    if (sizes.hasOwnProperty('men') && sizes['men'].length > 0) {
      active = 1;
      men_sizes = this.decorateSizes(sizes['men']);
    }
    let women_sizes = null;
    if (sizes.hasOwnProperty('women') && sizes['women'].length > 0) {
      if (!active) {
        active = 2;
      }
      women_sizes = this.decorateSizes(sizes['women']);
    }
    let kids_sizes = null;
    if (sizes.hasOwnProperty('kids') && sizes['kids'].length > 0) {
      if (!active) {
        active = 3;
      }
      kids_sizes = this.decorateSizes(sizes['kids']);
    }
    const estimate_delivery = (
      this.props.estimated_delivery != '' ? "Estimate Delivery: " + this.props.estimated_delivery : ''
    );
    let buyButton = 'Please Select a Size';
    if(this.state.item.hasOwnProperty('nid')){
      buyButton = 'Buy Now';
    }


    const authentic_badge_image = '/default/shield-outline.png';
    const delivery_badge_image = '/default/delivery.png';
    const delivery_info = shippingInfo(this.props.country);
    
    const tooltip = (
      <Tooltip key={"Tooltip"} placement="top" className="in" id="tooltip-top">We offer our Authenticity Guarantee on all purchases.</Tooltip>
    );
    return (
          <Form onSubmit={this.handleSubmit.bind(this)} id="buy-now-from">
            <div id='badge-wrapper' className="section-block clearfix">
              <div id="authentic-badge" data-toggle="tooltip" data-placement="top" title="" data-original-title="">
                <OverlayTrigger key={"OverlayTrigger"} placement="right" overlay={tooltip}>
                  <span><img className='' src={authentic_badge_image}width={24} height={24} title="100% Authentic Guarantee" /><span className='badge-info'>100% Authentic Guarantee</span></span>
                </OverlayTrigger>
              </div>
              <div id="delivery-badge" data-toggle="tooltip" data-placement="top" title="" data-original-title="">
                  <span><img className='' src={delivery_badge_image} width={19} height={19} title={delivery_info} /><span className='badge-info'>{delivery_info}</span></span>
              </div>
              
            </div>          
            <div className="section-block clearfix" >
              <Tabs key={"sizechart"} defaultActiveKey={active} animation={false} id="release-size">
                {men_sizes != undefined ? (
                  <Tab key={"men"}  eventKey={1} title="Men's Size">{men_sizes}</Tab>
                ) : ''}
                {women_sizes != undefined ? (
                  <Tab key={"women"} eventKey={2} title="Women's Size">{women_sizes}</Tab>
                ) : ''}
                {kids_sizes != undefined ? (
                  <Tab key={"kids"} eventKey={3} title="Kid's Size">{kids_sizes}</Tab>
                ) : ''}
                <Clearfix />
              </Tabs>
              <Button type="submit" id='buy-now-btn' block bsStyle="snkrly" className={this.state.item.hasOwnProperty('nid')?'btn-snkrly-dark':'btn-snkrly-dark'} dangerouslySetInnerHTML={{ __html: buyButton }} />
              <div id="estimate_delivery">{estimate_delivery}</div>
            </div>
          </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    estimated_delivery: state.settings.estimated_delivery ? state.settings.estimated_delivery : '',
    country: state.settings.location ? state.settings.location.snkrly_country_code : ''
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
    estimateDelivery: (nid) => {
      dispatch(estimate_delivery(nid));
    },
    selectSize: (item) => {
      dispatch({ type: 'BUY_NOW_SELECT_SIZE', alerts: [{ msg: 'Please select size' }] });
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    },
    clearEstimateDelivery: () => {
      dispatch({ type: 'CLEAR_ESTIMATE_DELIVERY' });
    }

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyNow));
