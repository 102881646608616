'use strict';
import React from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types'
import Media from 'react-bootstrap/lib/Media';
import Col from 'react-bootstrap/lib/Col';
import Clearfix from 'react-bootstrap/lib/Clearfix';
import { FormatCurrency, FormatDate, OrderStatuses, httpCorrectUrl } from '../Helper';

const OrderItem = (props) => {

    const item = props.item;
    const orderLink = '/order/' + item.orderId;
    const img = item.orderProductItems[0].images ? item.orderProductItems[0].images : '/default/image-not-available-grid.jpg';
    const src = httpCorrectUrl(img);
    const productName = item.orderProductItems[0].name;
    const orderTitle = "Order #" + item.orderId;
    const order_statuses = OrderStatuses();
    const order_status = order_statuses[item.orderStatus];

    const order_image = {
        backgroundImage: 'url(' + src + ')',
    };
    return (
        <Col xs={12} sm={6} md={6}>
            <div className="order-item" >
                <div className="order-item-products">
                    <Media>
                        <Media.Heading className="row">
                            <div className="col-xs-6 col-sm-6 col-md-6 order-txt"><Link to={orderLink}>{orderTitle}</Link></div>
                            <div className="col-xs-6 col-sm-6 col-md-6 order-date"><FormatDate value={item.orderCreated} format="lll" /></div>
                            <Clearfix />
                        </Media.Heading>
                        <Media.Left align="top">
                            <Link to={orderLink} className="order-image">
                                <img src={src} className="img-responsive" />
                            </Link>
                        </Media.Left>
                        <Media.Body>
                            <Link to={orderLink}>{productName}</Link>
                        </Media.Body>
                    </Media>
                </div>
                <div className="order-item-status">
                    <div className="pull-left"><b>Status:&nbsp;</b>{order_status}</div>
                    <div className="pull-right"><Link to={orderLink}><b>Total:&nbsp;</b><FormatCurrency value={item.orderTotal} /></Link></div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </Col>
    );
}

OrderItem.contextTypes = {
    router: PropTypes.object.isRequired
}
export default withRouter(OrderItem);
