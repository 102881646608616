// APP 
'use strict';
export const API_URL = () => {
    if (global.window) {
        const host = window.location.host;
        const protocol = window.location.protocol;
        const port = window.location.port ? ':' + port : '';
        const url = '//' + host + port;
        return url;
    } else {
	return 'https://snkr.ly';    
        //return '//' + process.env.SERVICE_URL;
    }
}

export const API_SEARCH_URL = () => {
    if (global.window) {
        const host = window.location.host;
        const protocol = window.location.protocol;
        const port = window.location.port ? ':' + port : '';
        const url = '//' + host + port;
        return url;
    } else {
	return 'https://snkr.ly';    
        //return '//' + process.env.SERVICE_URL;
    }
}

export const API_HEADERS = (influencer = {}, token = null) => {
    let headers = {
        'Content-Type': 'application/json'
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    if (influencer && influencer.id) {
        headers['Influencer'] = influencer.id;
    }
    return headers;
}

export const PROTOCOL = () => {
    if (global.window) {
        const protocol = window.location.protocol;
        return '//';
    } else {
        return 'https://' + process.env.SERVICE_URL;
    }
}


export const AXIOS_CONFIG = {
    withCredentials: true,
    baseURL: API_URL(),
    headers: API_HEADERS(),
    responseType: 'json',
    method: 'post',
}
