'use strict';
import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';


class InfluencerFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer id="footer">
      </footer>
    );
  }
}
InfluencerFooter.contextTypes = {
  router: PropTypes.object
};


export default withRouter(InfluencerFooter);
