'use strict';
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from './constants';

export const  view =  (slug, influencer = {}, token = null) => {
    return async (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/page/view',
            data: { slug: slug }
        });
        return await axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'PAGE_VIEW_SUCCESS',
                    page: slug,
                    title: json.data.title,
                    content: json.data.content,
                    statusCode: json.statusCode?json.statusCode:200,
                };
            } else {
                let statusCode = json.statusCode;
                let redirectObj = {path:'', searchString:'', statusCode: json.statusCode};
                if(json.statusCode == 301){
                    redirectObj.path = "/" + json.event.slug;
                }
                dispatch_json = {
                    type: 'PAGE_VIEW_FAILURE',
                    hydrated: false,
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors],
                    statusCode: json.statusCode?json.statusCode:404,
                    redirect: redirectObj,
                    statusCode: statusCode
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}
