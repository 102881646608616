'use strict';
const initialState = {
  content: {},
  // metatags: {}
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'PAGE_VIEW_SUCCESS':
      return Object.assign({}, state, {
        title: action.title,
        content: action.content,
        statusCode: action.statusCode
      });
    case 'CLEAR_PAGE':
      return Object.assign({}, initialState, { hydrated: true });
    default:
      return state;
  }
}
