'use strict';
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from './constants';
import ReactPixel from 'react-facebook-pixel';

export const view = (order_id, influencer, token, is_complete = false) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/view',
            data: { orderId: order_id, is_complete: is_complete }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'ORDER_VIEW_SUCCESS',
                    item: json.data,
                };
            } else {
                dispatch_json = {
                    type: 'ORDER_VIEW_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}


export const list = (body, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/list',
            data: body
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                let orderBody = {
                    type: 'ORDER_LIST_SUCCESS',
                    items: json.data.orders,
                    pageCount: Math.ceil(json.data.total / body.perPage),
                    page: body.page,
                    filters: {
                        orderId: '',
                        dateFrom: '',
                        dateTo: '',
                    }
                };
                if (body.orderId.length > 0) {
                    orderBody.filters.orderId = body.orderId;
                }
                if (body.dateFrom.length > 0) {
                    orderBody.filters.dateFrom = body.dateFrom;
                }
                if (body.dateTo.length > 0) {
                    orderBody.filters.dateTo = body.dateTo;
                }
                dispatch_json = orderBody;
            } else {
                dispatch_json = {
                    type: 'ORDER_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const checkout = (item, address, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const req_body = {
            item: { nid: parseInt(item.nid), oid: parseInt(item.oid), qty: parseInt(item.qty) },
            influencer: influencer,
        };
        const postBody = Object.assign({}, req_body, address);
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/checkout',
            data: postBody
        });
        return axios(options).then((response) => {
            const json = response.data;
            if (json.success) {
                dispatch({
                    type: 'CHECKOUT_SUCCESS',
                    item: json.data,
                    loading: 0,
                });
                const sz = item.size.replace('.', '_');
                const style_code = item.release.style_code
                    .replace('-', '')
                    .replace(' ', '')
                    .toUpperCase()
                    .trim();
                const id = style_code.replace(' ', '') + '-' + sz;// + '-' + g;
                const price = parseFloat(item.total_price);
                const pixel_json = {
                    content_name: item.release.name,
                    content_category: item.release.brand_name,
                    currency: 'USD',
                    content_type: 'product',
                    contents: [{
                        id: id,
                        quantity: 1,
                        item_price: price
                    }],
                    content_ids: [id],
                    value: price,
                    num_items: 1
                }
                ReactPixel.track('InitiateCheckout', pixel_json);
                window.gtag('event', 'checkout_progress', {
                    'event_label': item.title,
                });
                window.location = json.data.url;
            } else {
                dispatch({
                    type: 'CHECKOUT_FAILURE',
                    alerts: Array.isArray(json.errors) && json.errors.length ? json.errors : [{ msg: json.msg }]
                });
            }
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const checkout_complate = (orderId, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        dispatch({
            type: 'CHECKOUT_COMEPLETE',
            item: orderId,
        });

    };
}

export const messages = (orderId, influencer, token) => {
    return (dispatch) => {
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/messages',
            data: { orderId: orderId }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_LIST_SUCCESS',
                    messages: json.data,
                };
            } else {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const messageSend = (orderId, subject, message, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/messageSend',
            data: {
                orderId: parseInt(orderId),
                subject: subject,
                message: message
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch(messages(orderId, influencer, token));
                dispatch_json = {
                    type: 'ORDER_MESSAGES_SEND_SUCCESS',
                    messages: json.data,
                    alerts: [{ msg: json.msg }]
                };
            } else {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_SEND_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}