'use strict';
const initialState = {
  items: [],
  pageCount: 0,
  page: 1,
  total: 0,
  isServer: false
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'RELEASE_LIST_SUCCESS':
      return Object.assign({}, state, {
        // api: action.api,
        // metatags: action.metatags,
        items: action.items ? action.items : [],
        pageCount: action.pageCount,
        page: action.page,
        total: action.total,
        brandOrTag: action.brandOrTag ? action.brandOrTag:{},
        // isServer: action.hasOwnProperty('isServer') ? action.isServer : false
        // releaseType: action.releaseType,
        // filter: action.filter,
        // sortby: action.sortby,
        // keyword: action.keyword
      });
    case 'POPULAR_LIST_SUCCESS':  
      return Object.assign({}, state, {
        items: action.items ? action.items : [],
        isServer: action.hasOwnProperty('isServer') ? action.isServer : false
      });
    case 'CLEAR_RELEASES':  
      return Object.assign({}, initialState, { hydrated: true, isServer: false });
    default:
      return state;
  }
}
