'use strict';
import { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { smoothScroll } from './Helper';
import qs from 'query-string';
class ScrollToTop extends Component {
    componentDidUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            let params = Object.assign({}, location.search ? qs.parse(location.search.replace('?', '')) : {});
            let page = params.hasOwnProperty("page") ? parseInt(params.page) : 1;
            if(document.getElementById('release-header') !== null && page >1){
                smoothScroll.scrollTo('release-header');
            }else{
                smoothScroll.scrollTo('app');
            }
            
        }
    }
    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);