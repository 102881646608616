'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter';
import Grid from 'react-bootstrap/lib/Grid';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import { ReCaptcha } from 'react-recaptcha-v3';
import { forgotPassword } from '../../actions/auth';
import { setMetatags } from '../../actions/settings';
import { page_metas, FormGroupHelper, getReCaptchaSiteKey, loadReCaptcha } from '../Helper';
import Alerts from '../Alerts';

class Forgot extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.forgot;
    this.state = {
      pageTitle: metatags.title,
      email: '',
      gtoken: '',
      siteKey: getReCaptchaSiteKey(),
      action: "forgot"
    };
    this.props.setMetatags(metatags);
  }
  componentDidMount() {
    loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
  }

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  verifyCallback(recaptchaToken) {
    this.setState({ gtoken: recaptchaToken });
  }

  updateToken() {
    this.recaptcha.execute();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.updateToken();
    this.props.forgotPassword(this.state.email, this.state.gtoken, this.props.influencer);

  }

  render() {
    return (
      <Grid className="login-container clear-paddings">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <PageHeader>{this.state.pageTitle}</PageHeader>
          <Alerts alerts={this.props.alerts} />
          <HelpBlock>Enter your email address below and we'll send you password reset instructions.</HelpBlock>
          <FormGroupHelper
            id='email'
            name="email"
            type="email"
            label="Email"
            placeholder="Email"
            value={this.state.password}
            onChange={this.handleChange.bind(this)}
          />
          <ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey={this.state.siteKey}
            action={this.state.action}
            verifyCallback={this.verifyCallback.bind(this)}
          />
          <FormGroup>
            <Button type="submit" bsStyle="snkrly" className="pull-right">Reset Password</Button>
          </FormGroup>
        </Form>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    influencer: state.influencer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email, token, influencer) => {
      dispatch(forgotPassword(email, token, influencer));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));
