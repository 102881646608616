'use strict';
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from './constants';
import { httpCorrectUrl,ucfirst } from '../components/Helper';

export const  view =  (slug, influencer = {}, token = null) => {
    return async (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/release/view',
            data: { slug: slug }
        });
        return await axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'RELEASE_VIEW_SUCCESS',
                    item: json.data,
                    statusCode: json.statusCode?json.statusCode:200,
                };
            } else {
                let statusCode = json.statusCode;
                let redirectObj = {path:'', searchString:'', statusCode: json.statusCode};
                if(json.statusCode == 301){
                    redirectObj.path = "/" + json.event.slug;
                }else{ 
                    /*const search = slug.trim().split('-');
                    const words = search.map((item) => {
                        return ucfirst(item.trim());
                    });
                    const keyword = words.join('+');
                    redirectObj.path = '/search';
                    redirectObj.searchString = `keyword=${keyword}`;*/
                }
                dispatch_json = {
                    type: 'RELEASE_VIEW_FAILURE',
                    hydrated: false,
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors],
                    statusCode: json.statusCode?json.statusCode:404,
                    redirect: redirectObj,
                    statusCode: statusCode
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const search = (body, showloader = true, influencer = null, token = null) => {
    return (dispatch) => {
        if (showloader) {
            dispatch({
                type: 'APP_LOADER_LOADING',
            });
        }
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/release/search',
            data: body
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                if (json.data.items.length == 0) {
                    dispatch({
                        type: 'NO_RESULT',
                    });
                }

                dispatch_json = {
                    type: 'RELEASE_LIST_SUCCESS',
                    api: body.api,
                    items: json.data.items,
                    total: json.data.total,
                    pageCount: Math.ceil(json.data.total / body.perpage),
                    page: json.data.page,
                };
            } else {
                dispatch_json = {
                    type: 'RELEASE_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const popular = (body, showloader = true, influencer = null, token = null) => {
    return (dispatch) => {
        if (showloader) {
            dispatch({
                type: 'APP_LOADER_LOADING',
            });
        }
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/release/popular',
            data: body
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                if (json.data.items.length == 0) {
                    dispatch({
                        type: 'NO_RESULT',
                    });
                }
                dispatch_json = {
                    type: 'POPULAR_LIST_SUCCESS',
                    items: json.data.items,
                    hypetype: body.hypetype,
                };
            } else {
                dispatch_json = {
                    type: 'RELEASE_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}
export const categoryTagSearch = (body, showloader = true, influencer = null, token = null) => {
    return (dispatch) => {
        if (showloader) {
            dispatch({
                type: 'APP_LOADER_LOADING',
            });
        }
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/release/search',
            data: body
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                if (json.data.items.length == 0) {
                    dispatch({
                        type: 'NO_RESULT',
                    });
                }
                dispatch_json = {
                    type: 'RELEASE_LIST_SUCCESS',
                    api: body.api,
                    items: json.data.items,
                    total: json.data.total,
                    pageCount: Math.ceil(json.data.total / body.perpage),
                    page: json.data.page,
                    brandOrTag: json.data.brandOrTag,
                    releaseType: body.releaseType,
                };
            } else {
                let statusCode = 404;
                let redirectObj = {path:'', searchString:'', statusCode: 404};
                if(body.api === 'category'){
                    redirectObj.path = '/tag/'+ body.slug;
                    statusCode = 301;
                    
                }else{
                    /*const search = body.slug.trim().split('-');
                    const words = search.map((item) => {
                        return ucfirst(item.trim());
                    });
                    const keyword = words.join('+');
                    redirectObj.path = '/search';
                    redirectObj.searchString = `keyword=${keyword}`;*/
                }
                redirectObj.statusCode = statusCode;
                dispatch_json = {
                    type: 'RELEASE_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors],
                    redirect: redirectObj,
                    statusCode: statusCode
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            console.log('action_error',error);
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}
export const list = (body, showloader = true, influencer = null, token = null) => {
    return (dispatch) => {
        if (showloader) {
            dispatch({
                type: 'APP_LOADER_LOADING',
            });
        }
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/release/'+body.api,
            data: body
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                if (json.data.items.length == 0) {
                    dispatch({
                        type: 'NO_RESULT',
                    });
                }
                dispatch_json = {
                    type: 'RELEASE_LIST_SUCCESS',
                    items: json.data.items,
                    total: json.data.total,
                    pageCount: Math.ceil(json.data.total / body.perpage),
                    page: json.data.page,
                    releaseType: body?.type,
                    api: body.api,
                    filter: body.filter,
                    sortby: body.sortby,
                    keyword: body.keyword
                };
            } else {
                dispatch_json = {
                    type: 'RELEASE_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}



export const SearchApi = (body, successCallback, errorCallback) => {
    body.perpage = body.hasOwnProperty("perpage") ? body.perpage : 5;
    body.page = 1;
    body.exclude_fields = body.hasOwnProperty("exclude_fields") ? body.exclude_fields : ["prices", "style_code"];
    if (body.hasOwnProperty("filter")) {
        body.filter.location = "any";
    } else {
        body.filter = {
            location: "any"
        }
    }
    const options = Object.assign({}, AXIOS_CONFIG, {
        url: '/release/search',
        data: body
    });
    return axios(options).then((response) => {
        const json = response.data;
        if (json.success) {
            const items = json.data.items.map((item) => {
                item.image = httpCorrectUrl(item.image);
                return item;
            });
            const searchData = {
                data: items,
                total: json.data.total
            }
            successCallback(searchData);
        } else {
            errorCallback(json.msg);
        }
    }).catch((error) => {
        errorCallback(error);
    });
}

export const tranding = (body, successCallback, errorCallback) => {
    const options = Object.assign({}, AXIOS_CONFIG, {
        url: '/release/popular',
        data: body
    });
    return axios(options).then((response) => {
        const json = response.data;
        if (json.success) {
            if (json.data.items.length> 0) {
                const trandingData = {
                    items: json.data.items
                }
                successCallback(trandingData);
            }
        } else {
            errorCallback(json.msg);
        }
    }).catch((error) => {
        errorCallback(error);
    });
}
