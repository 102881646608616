'use strict';
import React, { Component} from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Grid from 'react-bootstrap/lib/Grid';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import {Search} from './Releases';
import { Helmet } from 'react-helmet-async';
import { getTitle } from './Helper';
import PropTypes from 'prop-types'
import {record404} from '../actions/settings';

class NotFound extends Component {
  constructor(props) {
      super(props);
      this.state = {

      }
  }
  componentDidMount() {
    if(this.props.location.pathname.replaceAll('/', '') !== 'error404'){
      let url = this.props.location.pathname.split('/');
      this.props.record404(this.props.location.pathname, this.props.influencer);
      const string = url[url.length - 1].replaceAll('-', '+');
      window.location.href = '/error/404?keyword='+string+'&url='+this.props.location.pathname;
    }
  }

  render() {
    const title = '404 Error - ' + getTitle(this.props.influencer);
    return (
      <>
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta property="og:title" content={title} />
            <meta property="robots" content="noindex" />
        </Helmet> 
        <Grid className="text-center">
          <PageHeader className="page-title">404 Error</PageHeader>
          <p>Oops!... Page Not Found</p>
        </Grid>
        <Search showTitle={false} />
      </>
    );
  }
};

NotFound.propTypes = {
  location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
      influencer: state.influencer,
      metatags: state.metatags,
      routing: state.routing
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      record404: async (url, influencer = {}, token = null) => {
          await dispatch(record404(url, influencer, token));
      }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFound));

