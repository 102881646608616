'use strict';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/lib/Alert';
import { smoothScroll } from './Helper';

class Alerts extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    // componentWillUpdate(nextProps, nextState) {
    if ((this.props.alerts.success != prevProps.alerts.success && this.props.alerts.success) ||
      (this.props.alerts.error != prevProps.alerts.error && this.props.alerts.error) ||
      (this.props.alerts.info != prevProps.alerts.info && this.props.alerts.info)) {
      smoothScroll.scrollTo('app');
    }
  }

  render() {
    return this.props.alerts.success ? (
      <Alert bsStyle="success">
        {this.props.alerts.success.map((message, index) => <div key={index}>{message.msg}</div>)}
      </Alert>
    ) : this.props.alerts.error ? (
      <Alert bsStyle="danger">
        {this.props.alerts.error.map((message, index) => <div key={index}>{message.msg}</div>)}
      </Alert>
    ) : this.props.alerts.info ? (
      <Alert bsStyle="info">
        {this.props.alerts.info.map((message, index) => <div key={index}>{message.msg}</div>)}
      </Alert>
    ) : null;
  }
}

export default Alerts;
