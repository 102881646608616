'use strict';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import Media from 'react-bootstrap/lib/Media';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import Image from 'react-bootstrap/lib/Image';
import Alerts from '../Alerts';
import { view } from '../../actions/order';
import { setMetatags } from '../../actions/settings';
import { FormatCurrency, FormatDate, OrderStatuses, httpCorrectUrl } from '../Helper';
import { OrderMessages } from './index';


class OrderView extends Component {
    constructor(props) {
        super(props);
        const orderId = this.props.match.params['orderId'];
        this.state = {
            pageTitle: 'Order #' + orderId,
            order: {}
        }
        const metatags = {
            title: this.state.pageTitle,
            keywords: 'order, orders, checkout',
            description: ''
        }
        this.props.setMetatags(metatags);
    }

    componentDidMount() {
        const orderId = this.props.match.params['orderId'];
        this.props.viewOrder(orderId, this.props.influencer, this.props.token);
    }

    componentWillUnmount() {
        this.props.clearOrder();
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.loading && props.order && props.order.orderId && (!state.order || state.order.orderId != props.order.orderId)) {
            return { order: props.order }
        }
        return null;
    }

    render() {
        const order = this.state.order;
        if (!order.orderProductItems) {
            if (this.props.loading) {
                return (
                    ''
                )
            } else {
                return (
                    <Alerts alerts={this.props.alerts} />
                )
            }
        }
        const order_statuses = OrderStatuses();

        const products = order.orderProductItems.length > 0 ? order.orderProductItems.map((product, index) => {
            const releaseLink = product.uri != null ? '/' + product.uri : '#';
            const img = product.images[0] ? product.images[0] : '/default/image-not-available-grid.jpg';
            const src = httpCorrectUrl(img).replace('0.app', '0app');
            return (
                <tr key={index}>
                    <td>
                        <Media>
                            <Media.Left align="top">
                                <Link to={releaseLink}>
                                    <Image width={64} src={src} alt="Image" />
                                </Link>
                            </Media.Left>
                            <Media.Body>
                                <div>
                                    <Link to={releaseLink}>
                                        {product.name}
                                    </Link>
                                </div>
                                {/* <div>Style Code: {product.styleCode}</div> */}
                            </Media.Body>
                        </Media>
                    </td>
                    <td>{product.size}</td>
                    <td>{product.qty}</td>
                    <td><FormatCurrency value={product.price * product.qty} /></td>
                </tr>
            );
        }) : <tr colSpan={4}></tr>;


        return (
            <Row id="order-view-wrapper" >
                <Col xs={12} sm={12} md={12}>
                    <PageHeader>{this.state.pageTitle}</PageHeader>
                    <Alerts alerts={this.props.alerts} />
                </Col>
                <Col xs={12} sm={4} md={4}>
                    <div id="order-status"><b>Order Status: </b>{order_statuses[order.orderStatus]}</div>
                    <div id="order-status"><FormatDate value={order.orderCreated} format="lll" prefix="Order Date: " /></div>
                    {expecteddelivered(order)}
                    {tracking(order)}
                    <h3>Shipping Address</h3>
                    <div id="shipping-address">
                        <div>
                            {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                        </div>
                        <div>
                            {order.shippingAddress.street1} {order.shippingAddress.street2} {order.shippingAddress.city}, {order.shippingAddress.stateCode} {order.shippingAddress.postalCode}, {order.shippingAddress.countryIsoCode2}
                        </div>
                        <div>
                            {order.shippingAddress.phoneNumber}
                        </div>
                    </div>
                    <hr />
                </Col>
                <Col xs={12} sm={8} md={8}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Size</th>
                                <th>Qty</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products}
                            <tr><td colSpan="3" className="td-text-align">Shipping</td><td><FormatCurrency value={this.props.order.orderShipping} /></td></tr>
                            <tr><td colSpan="3" className="td-text-align">Taxes</td><td><FormatCurrency value={this.props.order.orderTax ? this.props.order.orderTax : 0} /></td></tr>
                            <tr><td colSpan="3" className="td-text-align">Total</td><td><FormatCurrency value={this.props.order.orderTotal} /></td></tr>
                        </tbody>
                    </table>
                </Col>
                <Col xs={12} sm={12} md={12}>
                    <OrderMessages orderId={order.orderId} />
                </Col>
            </Row>
        );
    }
}

const expecteddelivered = (order) => {
    if (!order.delivered) {
        if (order.expectedDelivery) {
            return (
                <div id="order-expected">
                    <span className="item-txt">Expected Delivery: </span> <span className="item-value">{order.expectedDelivery}</span>
                </div>
            )
        }
    } else {
        return (
            <div id="order-delivered">
                <span className="item-txt">Delivered: </span> <span className="item-value">{order.deliveredDate}</span>
            </div>
        )
    }
    return (
        <div />
    )
};
const tracking = (order) => {
    if (order.trackingNumber) {
        let trackingNumber = <span>{order.trackingNumber}</span>;
        if (order.trackingUrl) {
            trackingNumber = <Link target="blank" to={order.trackingUrl}>{trackingNumber}</Link>;
        }
        return (
            <div id="order-tracking">
                <span className="item-txt">Tracking Number: </span> <span className="item-value">{trackingNumber}</span>
            </div>
        )
    }
    return (
        <div />
    )
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        influencer: state.influencer,
        alerts: state.alerts,
        user: state.auth.user,
        loading: state.settings.loading,
        order: state.order.item,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        viewOrder: (orderId, influencer, token) => {
            dispatch(view(orderId, influencer, token));
        },
        setMetatags: (metatags) => {
            dispatch(setMetatags(metatags));
        },
        clearOrder: () => {
            dispatch({ type: 'CLEAR_ORDER' });
        },
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderView));
