'use strict';
const initialState = {
  countries: [],
  provinces: [],
  loading: 0,
  tax_loading: 0,
  noitems: 0,
  estimated_delivery: '',
  estimated_tax: { is_loading: 0 },
  location: {},
  inf_signup: 0,
  redirect: {}
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'COUNTRIES_LOADING':
      return Object.assign({}, state, {
        loading: 1,
      });
    case 'COUNTRIES_SUCCESS':
      return Object.assign({}, state, {
        countries: action.items ? action.items : [],
        loading: 0,
      });
    case 'PROVINCES_LOADING':
      return Object.assign({}, state, {
        loading: 1,
        provinces: []
      });
    case 'PROVINCES_SUCCESS':
      return Object.assign({}, state, {
        provinces: action.items ? action.items : [],
        loading: 0
      });
    case 'CLEAR_ESTIMATE_DELIVERY':
      return Object.assign({}, state, {
        estimated_delivery: '',
      });
    case 'ESTIMATE_DELIVERY_SUCCESS':
      return Object.assign({}, state, {
        estimated_delivery: action.estimated_delivery ? action.estimated_delivery : '',
        loading: 0
      });
    case 'ESTIMATE_TAX_LOADING':
      return Object.assign({}, state, {
        estimated_tax: Object.assign({}, { is_loading: action.tax_loading }),
      });
    case 'ESTIMATE_TAX_SUCCESS':
      const estimate_tax = Object.assign({}, action.estimated_tax ? action.estimated_tax : {}, { is_loading: action.tax_loading });
      return Object.assign({}, state, {
        estimated_tax: estimate_tax
      });
    case 'CLEAR_ESTIMATE_TAX':
      return Object.assign({}, state, {
        estimated_tax: Object.assign({}, { is_loading: 0 }),
      });
    case 'APP_LOADER_LOADING':
      return Object.assign({}, state, {
        loading: 1,
      });
    case 'ESTIMATE_TAX_FAILURE':
      return Object.assign({}, state, {
        estimated_tax: Object.assign({}, { is_loading: 0 }),
      });
    case 'RELEASE_LIST_SUCCESS':
    case 'RELEASE_VIEW_SUCCESS':
    case 'PAGE_VIEW_SUCCESS':
    case 'PAGE_VIEW_FAILURE':  
    case 'POPULAR_LIST_SUCCESS':
    case 'POPULAR_LIST_FAILURE':
    case 'ORDER_LIST_SUCCESS':
    case 'ORDER_VIEW_SUCCESS':
    case 'COUNTRIES_FAILURE':
    case 'PROVINCES_FAILURE':
    case 'APP_LOADER_SUCCESS':
    case 'ORDER_VIEW_FAILURE':
    case 'ORDER_LIST_FAILURE':
    case 'CHECKOUT_FAILURE':
    case 'LOGIN_SUCCESS':
    case 'LOGIN_FAILURE':
    case 'LOGOUT_SUCCESS':
    case 'LOGOUT_FAILURE':
    case 'SIGNUP_SUCCESS':
    case 'SIGNUP_FAILURE':
    case 'FORGOT_PASSWORD_SUCCESS':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'RESET_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_FAILURE':
    case 'UPDATE_PROFILE_SUCCESS':
    case 'UPDATE_PROFILE_FAILURE':
    case 'INFLUENCER_UPDATE_SUCCESS':
    case 'INFLUENCER_UPDATE_FAILURE':
    case 'ORDER_MESSAGES_SEND_SUCCESS':
    case 'ORDER_MESSAGES_SEND_FAILURE':
    case 'CART_ADD_SUCCESS':
    case 'CART_ITEM_QTY_UPDATE':
    case 'CART_REMOVE_SUCCESS':
    case 'CART_EMPTY_SUCCESS':
    case 'NETWORK_ERROR':
    case 'INFLUENCER_DASHBOARD_LOADED_SUCCESS':
    case 'INFLUENCER_DASHBOARD_LOAD_FAILURE':
      return Object.assign({}, state, {
        loading: 0,
        isServer: action.hasOwnProperty('isServer') ? action.isServer : false
      });
    case 'INFLUENCER_CREATE_FAILURE':
      return Object.assign({}, state, {
        loading: 0,
        inf_signup: 0
      });
    case 'INFLUENCER_CREATE_SUCCESS':
      return Object.assign({}, state, {
        loading: 0,
        inf_signup: 1
      });
    case 'INFLUENCER_CREATE_RESET':
      return Object.assign({}, state, {
        inf_signup: 1
      });
    case 'TOP_INFLUENCERS_SUCCESS':
      return Object.assign({}, state, {
        top_influencers: action.top_influencers
      });
    case 'NO_RESULT':
      return Object.assign({}, state, {
        noitems: 1,
      });
    case 'GEOLOCATION_SUCCESS':
      return Object.assign({}, state, {
        location: action.location,
      });
    case 'RELEASE_VIEW_FAILURE':
    case 'RELEASE_LIST_FAILURE':
    case 'RELEASE_CATEGORYTAG_FAILURE':
      let redirectObj = {};
      if(action.hasOwnProperty('redirect')){
        redirectObj = action.redirect;
      }
      return Object.assign({}, state, {
        loading: 0,
        redirect : redirectObj
      });
    case 'SERVER_LOAD_END':
      return Object.assign({}, state, {
        isServer : false
      });      
    default:
      return state;
  }
}
