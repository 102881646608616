'use strict';
const initialState = {
  item: {},
  messages: {}
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }
  switch (action.type) {
    case 'ORDER_VIEW_SUCCESS':
      return Object.assign({}, state, {
        item: action.item ? action.item : {}
      });
    case 'ORDER_MESSAGES_LIST_SUCCESS':
      return Object.assign({}, state, {
        messages: action.messages ? action.messages : {}
      });
    case 'CLEAR_ORDER':
      return Object.assign({}, initialState, { hydrated: true });
    default:
      return state;
  }
}
