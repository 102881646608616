'use strict';
import axios from 'axios';
import moment from 'moment';
import cookie from 'react-cookie';
import { push } from 'react-router-redux';
import { API_HEADERS, AXIOS_CONFIG } from './constants';

export const login = (email, password, recaptchaToken, influencer) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer),
      url: '/login',
      data: {
        email: email,
        password: password,
        token: recaptchaToken
      }
    });
    return axios(options).then((response) => {
      let dispatch_json = {};
      const json = response.data;
      if (json.success) {
        dispatch_json = {
          type: 'LOGIN_SUCCESS',
          token: json.data.token,
          user: json.data.user
        };
        window.gtag('event', 'login', {
          'event_label': json.data.user.username,
        });
        saveCookie(json.data.token);
      } else {
        dispatch_json = {
          type: 'LOGIN_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        };
      }
      return dispatch(dispatch_json);
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const signup = (username, email, password, recaptchaToken, influencer) => {
  return (dispatch) => {
    dispatch({
      type: 'APP_LOADER_LOADING',
    });

    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer),
      url: '/signup',
      data: {
        username: username,
        email: email,
        password: password,
        token: recaptchaToken
      }
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'SIGNUP_SUCCESS',
          token: json.data.token,
          user: json.data.user
        });
        saveCookie(json.data.token);
        window.gtag('event', 'sign_up', {
          'event_label': json.data.user.username,
        });
        dispatch(push('/'));
      } else {
        dispatch({
          type: 'SIGNUP_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    })
      .catch((error) => {
        dispatch({
          type: 'NETWORK_ERROR',
        });
      });
  };
}

export const logout = (influencer, token) => {
  return (dispatch) => {

    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer),
      url: '/logout',
      data: {}
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'LOGOUT_SUCCESS',
        });
        cookie.remove('token', { path: '/' });
        /** Clear all cookies starting with 'session' (to get all cookies, omit regex argument) */
        Object.keys(cookie.select(/^session.*/i)).forEach(name => cookie.remove(name, { path: '/' }));
        dispatch(push('/'));
      } else {
        dispatch({
          type: 'LOGOUT_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const forgotPassword = (email, recaptchaToken, influencer) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer),
      url: '/forgot',
      data: {
        email: email,
        token: recaptchaToken
      }
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'FORGOT_PASSWORD_SUCCESS',
          alerts: [{ msg: json.msg }]
        });
      } else {
        dispatch({
          type: 'FORGOT_PASSWORD_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const resetPassword = (password, confirm, pathToken, recaptchaToken, influencer) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer),
      url: `/reset/${pathToken}`,
      data: {
        password: password,
        confirm: confirm,
        token: recaptchaToken
      }
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'RESET_PASSWORD_SUCCESS',
          alerts: [{ msg: json.msg }]
        });
      } else {
        dispatch({
          type: 'RESET_PASSWORD_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const updateProfile = (state, influencer, token) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer, token),
      url: '/account',
      method: 'put',
      data: {
        email: state.email,
        username: state.username,
        gender: state.gender,
        newAvatar: state.newAvatar,
        firstname: state.firstname,
        lastname: state.lastname,
        address: state.address
      }
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'UPDATE_PROFILE_SUCCESS',
          alerts: [{ msg: json.msg }],
          user: json.data.user,
          newAvatar: ''
        });
      } else {
        dispatch({
          type: 'UPDATE_PROFILE_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const changePassword = (password, confirm, influencer, token) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });

    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer, token),
      url: '/account/password',
      method: 'put',
      data: {
        password: password,
        confirm: confirm
      }
    });
    return axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        dispatch({
          type: 'CHANGE_PASSWORD_SUCCESS',
          alerts: [{ msg: json.msg }]
        });

      } else {
        dispatch({
          type: 'CHANGE_PASSWORD_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const deleteAccount = (influencer, token) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });

    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: API_HEADERS(influencer, token),
      url: '/account',
      method: 'delete'
    });
    return axios(options).then((response) => {
      let dispatch_json = {};
      const json = response.data;
      if (json.success) {
        dispatch(logout());
        dispatch_json = {
          type: 'DELETE_ACCOUNT_SUCCESS',
          alerts: [{ msg: json.msg }]
        };
      } else {
        dispatch_json = {
          type: 'DELETE_ACCOUNT_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        };
      }
      return dispatch(dispatch_json);
    }).catch((error) => {
      dispatch({
        type: 'NETWORK_ERROR',
      });
    });
  };
}

export const saveCookie = (token) => {
  cookie.save('token', token, {
    path: '/',
    expires: moment().add(1, 'week').toDate(),
    sameSite: 'lax',
    // httpOnly: true,
    // secure: true
  });
}

