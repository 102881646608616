'use strict';
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from './constants';
import { saveCookie } from './auth';

export const load = (slug) => {
    return (dispatch) => {
        dispatch({
            type: 'INFLUENCER_LOADING'
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/influencer/load',
            data: { slug: slug }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                const metatags = {
                    title: json.result.username,
                    description: json.result.description,
                    canonical: 'http://example.com/path/to/page',
                    meta: {
                        charset: 'utf-8',
                        name: {
                            keywords: json.result.title + ', ' + json.result.style_code
                        }
                    }
                };
                dispatch_json = {
                    type: 'INFLUENCER_LOADED_SUCCESS',
                    item: json.result,
                    loading: 0,
                    metatags: metatags
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_LOAD_FAILURE',
                    alerts: Array.isArray(json) ? json : [json]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const loadUser = (slug) => {
    return (dispatch) => {
        dispatch({
            type: 'INFLUENCER_LOADING'
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/influencer/load',
            data: { slug: slug }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                const metatags = {
                    title: json.result.username,
                    description: json.result.description,
                    canonical: 'http://example.com/path/to/page',
                    meta: {
                        charset: 'utf-8',
                        name: {
                            keywords: json.result.title + ', ' + json.result.style_code
                        }
                    }
                };
                dispatch_json = {
                    type: 'INFLUENCER_LOADED_SUCCESS',
                    item: json.result,
                    loading: 0,
                    metatags: metatags
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_LOAD_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}
export const login = (email, password) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        dispatch({
            type: 'APP_LOADER_LOADING',
        });

        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/influencer/login',
            data: {
                email: email,
                password: password
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'LOGIN_SUCCESS',
                    token: json.token,
                    user: json.user
                };
                saveCookie(json.token);
            } else {
                dispatch_json = {
                    type: 'LOGIN_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const signup = (influencer, recaptchaToken) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });

        const options = Object.assign({}, AXIOS_CONFIG, {
            url: '/influencer/signup',
            data: {
                firstname: influencer.firstname,
                lastname: influencer.lastname,
                username: influencer.username,
                subdomain: influencer.subdomain,
                socials: influencer.socials,
                phonenumber: influencer.phonenumber,
                email: influencer.email,
                website: influencer.website,
                password: influencer.password,
                repeatpassword: influencer.repeatpassword,
                token: recaptchaToken
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'INFLUENCER_CREATE_SUCCESS',
                    item: json.result,
                    alerts: [{ msg: json.msg }]
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_CREATE_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const updateProfile = (profile, token) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING',
        });

        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/influencer',
            method: 'put',
            data: {
                website: profile.website,
                sitename: profile.sitename,
                about: profile.about,
                newLogo: profile.newLogo,
                socials: profile.socials,
                settings: profile.settings,
                analytics: profile.analytics,
                slogon: profile.slogon,
                phonenumber: profile.phonenumber,
                paypalemail: profile.paypalemail
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'INFLUENCER_UPDATE_SUCCESS',
                    alerts: [json],
                    influencer: json.influencer
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_UPDATE_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}


export const getinfluencer = (id, token) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING'
        });

        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/influencer/load',
            data: { slug: slug }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                const metatags = {
                    title: json.result.username,
                    description: json.result.description,
                    canonical: 'http://example.com/path/to/page',
                    meta: {
                        charset: 'utf-8',
                        name: {
                            keywords: json.result.title + ', ' + json.result.style_code
                        }
                    }
                };
                dispatch_json = {
                    type: 'INFLUENCER_LOADED_SUCCESS',
                    item: json.result,
                    loading: 0,
                    metatags: metatags
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_LOAD_FAILURE',
                    alerts: Array.isArray(json) ? json : [json]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const dashboard = (id, from, to, token) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING'
        });

        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/influencer/states',
            data: {
                id: id,
                from: from,
                to: to
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'INFLUENCER_DASHBOARD_LOADED_SUCCESS',
                    states: json.result,
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_DASHBOARD_LOAD_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const views = (id, from, to, token) => {
    return (dispatch) => {
        dispatch({
            type: 'APP_LOADER_LOADING'
        });


        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS({}, token),
            url: '/influencer/dailyviews',
            data: {
                id: id,
                from: from,
                to: to
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'INFLUENCER_PAGE_VIEWS_LOADED_SUCCESS',
                    states: json.result,
                };
            } else {
                dispatch_json = {
                    type: 'INFLUENCER_DASHBOARD_LOAD_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}