import React from 'react';
import { Redirect } from "react-router-dom";
export const  redirects = [
    {from: "/tag/adidas", to:"/category/adidas"},
    {from: "/tag/asics", to:"/category/asics"},
    {from: "/tag/converse", to:"/category/converse"},
    {from: "/tag/new-balance", to:"/category/new-balance"},
    {from: "/tag/nike", to:"/nike-release-dates"},
    {from: "/tag/reebok", to:"/category/reebok"},
    {from: "/tag/vans", to:"/category/vans"},
    {from: "/tag/jordan-brand", to:"/air-jordan-release-dates"},
    {from: "/tag/puma", to:"/category/puma"},
    {from: "/tag/under-armour", to:"/category/under-armour"},
    {from: "/tag/yeezy", to:"/yeezy-release-dates"},
    {from: "/release/:slug", to: "/:slug"},
];
export const  KicksOnFireRedirects = () => {

    const kofRedirect = redirects.map((item, index)=>{
        const key = index +1;
        return (<Redirect key={key} from={item.from} to={item.to} />);
    });
    return kofRedirect;
}