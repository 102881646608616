'use strict';
import React, { Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { API_URL } from '../actions/constants';
// import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';
import {get_site_url, getTitle} from './Helper';

const default_metatagas = {
    title: 'SNKR.LY',
    description: '',
    canonical: API_URL(),
    keywords: 'sneakers, kicks, snkrly, kixify, kicksonfire',
    charset: 'utf-8',
}

class SiteMetatags extends Component {
    constructor(props) {
        super(props);
        const metatags = props.metatags;
        let title = (metatags.title ? metatags.title : '');
        if(metatags.hasOwnProperty('page') && metatags.page > 1){
            title += ' - Page ' + metatags.page; 
        }
        if(title.length){
            title += ' - ';
        }

        this.state = {
            title: title + getTitle(props.influencer),
            description: (metatags.description ? metatags.description : '') + props.influencer.slogon,
            canonical: metatags?.url?.length>0 ? metatags.url :get_site_url(props),
            keywords: (metatags.keywords ? metatags.keywords + ', ' : '') + default_metatagas.keywords,
            charset: 'utf-8',
            image: metatags?.image?.length>0 ? metatags.image :'',
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state != prevState) {
            const ga_adwords = this.props.influencer.analytics && this.props.influencer.analytics.adwords ? this.props.influencer.analytics.adwords : '';
            if (ga_adwords) {
                window.gtag('config', ga_adwords);
            }
            window.gtag('config', 'UA-114936533-1', {
                'page_title': this.state.title,
                'page_path': window.location.pathname + window.location.search,
            });
            window.gtag('set', 'linker', {
                'domains': ['kicksonfire.com', 'snkr.ly', 'jordandepot.com', 'sneakerfiles.com', 'kickfeedshop.com', 'snkrdeals.com', 'kicks.worldstar.com', 'kicks.hoodville.com', 'woahkicks.com'],
                'accept_incoming': true
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.metatags?.title?.length > 0) {
            const meta = props.metatags;
            let title = (meta.title ? meta.title : '');
            if(meta.hasOwnProperty('page') && meta.page > 1){
                title += ' - Page ' + meta.page; 
            }
            if(title.length){
                title += ' - ';
            }            
            title += getTitle(props.influencer);
            if (title != state.title) {
                const canonical = props.metatags?.url?.length>0 ? props.metatags.url : get_site_url(props);
                const keywords = (meta.keywords ? meta.keywords + ', ' : '') + default_metatagas.keywords;
                // const title = (meta.title ? meta.title + ' - ' : '') + getTitle(props.influencer);
                return {
                    title: title,
                    description: meta.description ? meta.description : '',
                    canonical: canonical,
                    keywords: keywords,
                    image: meta.image ? meta.image : '',
                }
            }
        }
        return null;
    }

    render() {
        return (
            // <React.Fragment>
                <Helmet>
                    <title>{this.state.title}</title>
                    <meta name="title" property="og:title" content={this.state.title} />
                    <meta name="description" property="og:description" content={this.state.description} />
                    <link rel="canonical" href={this.state.canonical} />
                    <meta name="keywords" content={this.state.keywords} />
                    <meta property="og:title" content={this.state.title} />
                    <meta property="og:description" content={this.state.description} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={this.state.canonical} />
                    <meta property="og:locale" content="en_US" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content={this.props.influencer.socials?.twitter? this.props.influencer.socials.twitter:"@kicksonfire"} />
                    <meta name="twitter:site" content={this.props.influencer.socials?.twitter? this.props.influencer.socials.twitter:"@kicksonfire"} />                    
                    <meta name="twitter:url" content={this.state.canonical} />
                    <meta name="twitter:title" content={this.state.title} />
                    <meta name="twitter:description" content={this.state.description} />
                    <meta name="twitter:domain" content={this.props.influencer.website} />
                    <meta name="twitter:title" content={this.state.title} />
                    {this.state.image.length ? <meta name="image" content={this.state.image} />:''}
                    {this.state.image.length ? <meta property="og:image" content={this.state.image} />:''}
                    {this.state.image.length ? <meta property="og:image:secure_url" content={this.state.image}/>:''} 
                    {/* {this.state.image.length ? <meta property="og:image:type" content="image/png" />:''}  */}
                    {this.state.image.length ? <meta property="og:image:alt" content={this.state.title} />:''}
                    {/* {this.state.image.length ? <meta property="og:image:width" content="1080" />:''} 
                    {this.state.image.length ? <meta property="og:image:height" content="720"/>:''} */}
                    {this.state.image.length ? <meta name="twitter:image" content={this.state.image} />:''} 
                    {this.state.image.length ? <meta name="twitter:image:alt" content={this.state.title}/>:''} 
                </Helmet> 
            // </React.Fragment>
        );
    }
}

SiteMetatags.contextTypes = {
    router: PropTypes.object.isRequired
}

SiteMetatags.propTypes = {
    metatags: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        influencer: state.influencer,
        metatags: state.metatags,
        routing: state.routing
    };
};

export default connect(mapStateToProps)(SiteMetatags);