import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

class FilterSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brands: [],
            genders: [
                {
                    value: "men",
                    label: "Men"
                },
                {
                    value: "women",
                    label: "Women"
                },
                {
                    value: "youth",
                    label: "Youth"
                }],
            shippings: [
                {
                    value: "usa",
                    label: "Usa"
                },
                {
                    value: "canada",
                    label: "Canada"
                },
                {
                    value: "world",
                    label: "Worldwide"
                }],
            sizes: [1, 2, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 16, 17, 18]
        }
        if(props.showBrand && props.hasOwnProperty('categories') && props.categories.length>0){
            const brands = props.categories.map((item)=>{
                return {
                    value: item.name.toLowerCase(),//parseInt(item.id),
                    label: item.name
                }
            });
            this.state = Object.assign({}, this.state, {brands:brands});
        }
    }

    onShippingChange(value) {
        let newShipping = "";
        if (this.props.shipping.length == 0 || value != this.props.shipping) {
            newShipping = value;
        }
        this.props.setShipping(newShipping);
    }

    onGenderChange(value) {
        let newGenders = new Set(this.props.genders);
        if (newGenders.has(value)) {
            newGenders.delete(value);
        } else {
            newGenders.add(value);
        }
        this.props.setGenders(Array.from(newGenders));
    }

    onBrandChange(value) {
        let newBrands = new Set(this.props.brands);
        if (newBrands.has(value)) {
            newBrands.delete(value);
        } else {
            newBrands.add(value);
        }
        this.props.setBrands(Array.from(newBrands));
    }

    onSizeChange(value) {
        let newSizes = new Set(this.props.sizes);
        if (newSizes.has(value)) {
            newSizes.delete(value);
        } else {
            newSizes.add(parseFloat(value));
        }
        this.props.setSizes(Array.from(newSizes));
    }

    clearFilter() {
        this.props.clearFilter();
    }

    render() {
        const brands = this.state.brands.map(element => {
            let active = this.props.brands.includes(element.value) ? " active" : ""
            return (
                <Col xs={4} key={element.value}>
                    <button className={"btn btn-snkrly" + active} onClick={() => this.onBrandChange(element.value)}>
                        {element.label}
                    </button>
                </Col>
            )
        });
        const genders = this.state.genders.map(element => {
            let active = this.props.genders.includes(element.value) ? " active" : ""
            return (
                <Col xs={4} key={element.value}>
                    <button className={"btn btn-snkrly" + active} onClick={() => this.onGenderChange(element.value)}>
                        {element.label}
                    </button>
                </Col>
            )
        });

        const sizes = this.state.sizes.map(size => {
            let active = this.props.sizes.includes(size) ? " active" : ""
            return (
                <div key={size} className="btn-size-wrapper">
                    <button className={"btn btn-snkrly" + active} onClick={() => this.onSizeChange(size)}>{size}</button>
                </div>
            )
        });

        const shippings = this.state.shippings.map(element => {
            let active = this.props.hasOwnProperty("shipping") && this.props.shipping == element.value ? " active" : ""
            return (
                <Col xs={4} key={element.value}>
                    <button className={"btn btn-snkrly" + active} onClick={() => this.onShippingChange(element.value)}>
                        {element.label}
                    </button>
                </Col>
            )
        });
        const apis = ['under_retail', 'latest'];
        return (
            <div id="filter-contianer">
                <div className="filter-header">
                    <span className="name">Filter</span>
                    <div className="clean-btn-wrapper">
                        <button className="btn btn-snkrly-dark" onClick={() => this.clearFilter()}>Clear Filters</button>
                    </div>
                </div>
                <div id="filter-wrapper">
                    {brands.length > 0 ? (
                        <div id="brand-filter" className="filter-block">
                            <div className="filter-title">Brands</div>
                            <Row className="filter-group-button">
                                {brands}
                            </Row>
                        </div>
                    ) : ''}
                    {genders.length > 0 && this.props.api !='latest'? (
                        <div id="gender-filter" className="filter-block">
                            <div className="filter-title">Gender</div>
                            <Row className="filter-group-button">
                                {genders}
                            </Row>
                        </div>
                    ) : ''}
                    {sizes.length > 0 && this.props.api !='latest'? (
                        <div id="size-filter" className="filter-block">
                            <div className="filter-title">Size</div>
                            <div className="filter-group-button size">
                                {sizes}
                            </div>
                        </div>
                    ) : ''}
                    { !apis.includes(this.props.api) && shippings.length > 0 ? (
                        <div id="shipping-filter" className="filter-block">
                            <div className="filter-title">Shipping</div>
                            <Row className="filter-group-button">
                                {shippings}
                            </Row>
                        </div>
                    ) : ''}
                    <hr />
                    <div className="clean-btn-wrapper">
                        <button className="btn btn-snkrly-dark" onClick={() => this.clearFilter()}>Clear Filters</button>
                    </div>                    
                    <div className="close-btn-wrapper">
                        <button className="btn btn-snkrly-dark" onClick={() => this.props.toggleSidebar()}>
                            Close
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}

export default FilterSidebar;