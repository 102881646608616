'use strict';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { decorateMenuItem, decorateMenuButton } from './Helper';
import {InfluencerFooter} from './Influencer';
import { tranding } from '../actions/release';
import PropTypes from 'prop-types'

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSearch: {},
      popular: [],
      isLoading: false,
      showTrands:true,
    }
    // const location = props.location;
    // const pathname = location.pathname.replace('/','');
    // if(['account', 'login','signup'].includes(pathname)){
    //     this.state.showTrands = false;
    // }

    this.getListTranding();
  }

  getListTranding() {
    let body = {
      hypetype: 'upcoming',
      limit: 10
    }
    tranding(body,
        (result) => {
            this.setState({
                popular: result.items,
            })
        },
        (err) => {
            this.setState({
                isLoading: false,
            })
        })
  }
  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      const location = props.location;
    
      const pathname = location.pathname.replace('/','');
      if(['account', 'login', 'signup', 'orders'].includes(pathname)){
          state.showTrands = false;
      }else{
        state.showTrands = true;
      }   
      if (props.user) {
        if(typeof window !== 'undefined' && window.ire){
          const customerId = props.user.id ? props.user.id:'';
          window.ire('identify', {customerId: customerId, customerEmail: props.user.customerSHA1});
        }
      }
    }
    return null;
  }

  render() {
    if (!this.props.influencer || !this.props.influencer.status) {
      return (
        <InfluencerFooter />
      )
    }
    if(this.props.loading){
      return (
        <></>
      )
    }
    const store_name = this.props.influencer.sitename ? this.props.influencer.sitename : (this.props.influencer.subdomain ? this.props.influencer.subdomain : 'SNKR.LY');
    let socials = new Array();

    let powered_by = <span>Powered by < Link to="//snkr.ly" target="blank" > SNKR.LY</Link ></span>
    if (this.props.influencer && this.props.influencer.status) {
      const re = /^((https?|http):\/\/|www\.)/;
      if (this.props.influencer.socials.instagram) {
        let ins_url = this.props.influencer.socials.instagram;
        if (re.test(ins_url) === false) {
          ins_url = '//instagram.com/' + ins_url;
        } else {
          ins_url = ins_url.replace(re, '//');
        }
        socials.push(<Link to={ins_url} title="Instagram" className="social-links" target="blank"><i className="fab fa-instagram fa-2x" aria-hidden="true"></i></Link>);
      } 
      if (this.props.influencer.socials.twitter) {
        let tw_url = this.props.influencer.socials.twitter;
        if (re.test(tw_url) === false) {
          tw_url = '//twitter.com/' + tw_url;
        } else {
          tw_url = tw_url.replace(re, '//');
        }
        socials.push(<Link to={tw_url} title="Twitter" className="social-links" target="blank"><i className="fab fa-twitter fa-2x" aria-hidden="true"></i></Link>);
      }        
      if (this.props.influencer.socials.facebook) {
        let fb_url = this.props.influencer.socials.facebook;
        if (re.test(fb_url) === false) {
          fb_url = '//facebook.com/' + fb_url;
        } else {
          fb_url = fb_url.replace(re, '//');
        }
        socials.push(<Link to={fb_url} title="Facebook" className="social-links" target="blank"><i className="fab fa-facebook  fa-2x" aria-hidden="true"></i></Link>);
      }
      if (this.props.influencer.socials.youtube) {
        let you_url = this.props.influencer.socials.youtube;
        if (re.test(you_url) === false) {
          you_url = '//youtube.com/' + you_url;
        } else {
          you_url = you_url.replace(re, '//');
        }
        socials.push(<Link to={you_url} title="Youtube" className="social-links" target="blank"><i className="fab fa-youtube fa-2x" aria-hidden="true"></i></Link>);
      }
      powered_by = '';
    }

    const social_icons = socials.map((link, index) => {
      return <div className="social-item" key={index}>{link}</div>;
    });

    const tranding = this.state?.popular?.length>0?this.state.popular.map((item, index) => {
      if(index < 10){
        return decorateMenuButton('/' + item.slug, item.title, index, 'btn-snkly-small')
      }else{
        return '';
      }
    }):null;

    return (
      <footer id="footer" className='clearfix'>
        <Grid>
          {this.state.showTrands?
          <Row>
            <Col xs={12} sm={12} md={12} className="clear-paddings">
              <div id="releases-date-tabs" className="kf-nav-wrapper">
                <ul className="nav nav-pills">
                  <li><h3 className="kf-block-title">Release Dates</h3></li>
                  {decorateMenuButton('/air-jordan-release-dates', 'Jordan', 'air-jordan', 'btn-block')}
                  {decorateMenuButton('/nike-release-dates', 'Nike', 'nike', 'btn-block')}
                  {decorateMenuButton('/sneaker-release-dates', 'Sneaker', 'sneaker', 'btn-block')}
              </ul>
                </div>
            </Col>
          </Row> :''} 
          {this.state.showTrands && tranding?.length>0?
            <Row>
              <Col xs={12} sm={12} md={12} className="clear-paddings section-related-releases type-tags clear-margins">
                <div className="kof-block-headline">
                    <h3 className="kf-block-title">Trending</h3>
                </div>
                <div className="kof-block-content">
                  <div className="kf-nav-wrapper tags">
                    <ul className="nav nav-pills">{tranding}</ul>
                  </div>
                </div>
              </Col>
            </Row>:''
          } 
        
          <div className='navbar navbar-default'>
            <div id='copyright'>
                <p>© 2003-{(new Date()).getFullYear()} {store_name}. {powered_by}</p>
            </div>
            <div  id='footer-naveigation'>
              <ul className="nav navbar-nav">
                  {decorateMenuItem('/faq', 'FAQ', 'fag')}
                  {decorateMenuItem('/privacy', 'Privacy', 'privacy')}
                  {decorateMenuItem('/terms', 'Terms', 'terms')}
                  {/* {decorateMenuItem('/about', 'About')} */}
              </ul>
            </div> 
            <div id='social-items'>
                <div className="social-items-wrapper">{social_icons}</div>
            </div>   
          </div>
        </Grid>
      </footer>
    );
  }
}

Footer.propTypes = {
  location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    influencer: state.influencer,
    user: state.auth.user,
    loading: state.settings.loading,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
