'use strict';
const initialState = {
  items: [],
  pageCount: 1,
  page: 0,
  filters: {}
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }
  switch (action.type) {
    case 'ORDER_LIST_SUCCESS':
      return Object.assign({}, state, {
        items: action.items ? action.items : [],
        pageCount: action.pageCount,
        page: action.page
      });
    case 'CLEAR_ORDERS':
      return Object.assign({}, initialState, { hydrated: true });
    default:
      return state;
  }
}
