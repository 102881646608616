'use strict';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux'
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import { ReCaptcha } from 'react-recaptcha-v3';

import { setMetatags } from '../../actions/settings';
import { login } from '../../actions/auth';
import { facebookLogin, twitterLogin, googleLogin } from '../../actions/oauth';
import Alerts from '../Alerts';
import PropTypes from 'prop-types';
import { page_metas, FormGroupHelper, getReCaptchaSiteKey, loadReCaptcha, LoginSignupNavigation} from '../Helper';


class Login extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.login;
    this.state = {
      pageTitle: metatags.title,
      email: '',
      password: '',
      gtoken: '',
      siteKey: getReCaptchaSiteKey(),
      action: 'login'
    };
    this.props.setMetatags(metatags);
  }

  componentDidMount() {
    loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      this.props.clearAlerts();
      const route_state = this.context.router.route.location.state;
      const url = (route_state && route_state.referrer) ? this.context.router.route.location.state.referrer : '/';
      this.context.router.history.push(url);
    }
  }

  // componentWillUnmount() {
  //   this.props.clearAlerts();
  // }

  verifyCallback(recaptchaToken) {
    this.setState({ gtoken: recaptchaToken });
  }

  updateToken() {
    this.recaptcha.execute();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.updateToken();
    this.props.login(this.state.email, this.state.password, this.state.gtoken, this.props.influencer);
  }

  handleFacebook() {
    this.props.facebookLogin(this.props.influencer);
  }

  handleTwitter() {
    this.props.twitterLogin(this.props.influencer);
  }

  handleGoogle() {
    this.props.googleLogin(this.props.influencer);
  }

  render() {
    return (
      <Grid className="login-container clear-paddings"  id="login-signup-tabs">
        {LoginSignupNavigation(this.state.action)}
        <Row className="tab-content">
          <Col lg={12}>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              {/* <PageHeader>{this.state.pageTitle}</PageHeader> */}
              <Alerts alerts={this.props.alerts} />
              <div className="login-signup-form">
                <FormGroupHelper
                  id='email'
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
                <FormGroupHelper
                  id='password'
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                />
                <ReCaptcha
                  ref={ref => this.recaptcha = ref}
                  sitekey={this.state.siteKey}
                  action={this.state.action}
                  verifyCallback={this.verifyCallback.bind(this)}
                />
                <FormGroup><Link to="/forgot"><strong>Forgot your password?</strong></Link></FormGroup>
              </div>
              <Button type="submit" bsStyle="snkrly" className="btn-snkrly-dark btn-block">Log in</Button>
            </Form>
            {/* <SocialLoginSignup fbevent={this.handleFacebook.bind(this)} twevent={this.handleTwitter.bind(this)} /> */}
            <HelpBlock className="text-center">
              Don't have an account? <Link to="/signup"><strong>Sign up</strong></Link>
            </HelpBlock>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

Login.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    influencer: state.influencer,
    isAuthenticated: state.auth.token ? true : false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, gtoken, influencer) => {
      dispatch(login(email, password, gtoken, influencer));
    },
    facebookLogin: (influencer) => {
      dispatch(facebookLogin(influencer));
    },
    twitterLogin: (influencer) => {
      dispatch(twitterLogin(influencer));
    },
    googleLogin: (influencer) => {
      dispatch(googleLogin(influencer));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
