import React from 'react';
import { Link } from 'react-router-dom';
import ucfirst from 'ucfirst';
import Col from 'react-bootstrap/lib/Col';
import { httpCorrectUrl, FormatEventDate, dateFormatReleative } from '../Helper';

const ReleaseItem = (props) => {
    const item = props.item;
    const dateAttr = props.hasOwnProperty('dateAttr') ? props.dateAttr : 'event_date';
    const link = '/' + item.slug;
    const src = httpCorrectUrl(item.image);
    const title = ucfirst(item.title);
    const mobile = props.hasOwnProperty('mobilesize')?props.mobilesize:6;
    const showPrice = props.hasOwnProperty('showPrice')? props.showPrice : true;
    return (
        <Col xs={mobile} sm={3} md={3} lg={3} className="release-item-continer">
            <Link  to={link} className='release-item'>
                {showPrice?<ItemPrice event_date={item.event_date} product_prices={item.product_prices} prices={item.product_prices} country={props.country} />:''}
                <span className="release-item-image">
                    <img width={260} height={170} id={item.id} className='img-responsive' alt={title} src={src} />
                    <ItemDicount  product_prices={item.product_prices} prices={item.prices} country={props.country} />
                </span>
                <span className="release-item-title">{title}</span>
            </Link>
        </Col>
    );
}

const ReleaseItem1 = (props) => {
    const item = props.item;
    const dateAttr = props.hasOwnProperty('dateAttr') ? props.dateAttr : 'event_date';
    const link = '/' + item.slug;
    const src = httpCorrectUrl(item.image);
    const title = ucfirst(item.title);
    const mobile = props.hasOwnProperty('mobilesize')?props.mobilesize:6;
    const showPrice = props.hasOwnProperty('showPrice')? props.showPrice : true;
    return (
        <Col xs={mobile} sm={3} md={3} lg={3} className="release-item-continer">
            <div className='release-item'>
                {showPrice?<ItemPrice event_date={item.event_date} product_prices={item.product_prices} prices={item.product_prices} country={props.country} />:''}
                <div className="release-item-image">
                    <Link to={link}>
                        <img id={item.id} className='img-responsive' alt={title} src={src} />
                    </Link>
                    <ItemDicount  product_prices={item.product_prices} prices={item.prices} country={props.country} />
                </div>
                <div className="release-item-title">
                    <Link to={link}>{title}</Link>
                </div>
            </div>
        </Col>
    );
}

const ItemPrice = (props) => {
    let priceValue = '';
    const user_price = props.product_prices ? (props.product_prices[props.country] ? props.product_prices[props.country] : (props.product_prices['usa'] ? props.product_prices['usa'] : null)) : null;
    if (user_price && user_price > 1) {
        const price = Math.round(user_price).toLocaleString();
        priceValue = '$' + price;
    }else if(props.event_date){
        priceValue = dateFormatReleative({value: props.event_date});
    }
    return <span className="release-price-from">{priceValue}</span>;
}
const ItemPrice1 = (props) => {
    const user_price = props.product_prices ? (props.product_prices[props.country] ? props.product_prices[props.country] : (props.product_prices['usa'] ? props.product_prices['usa'] : null)) : null;
    if (user_price && user_price > 1) {
        const price = Math.round(user_price).toLocaleString();
        return (
            <div className="release-price-from">
                <span className='buy-from-price'>{'$' + price}</span>
            </div>
        )
    }else if(props.event_date){
        return (
            <div className="release-price-from">
                <span className="buy-from-price">{dateFormatReleative({value: props.event_date})}</span>
            </div>
        )
    }
    return '';
}
const ItemDate = (props) => {
    const dateAttr = props.showDate ? (props.dateAttr) : null;
    if (dateAttr) {
        return (
            <div className="meta-info">
                <span className="td-post-date">
                    <FormatEventDate value={dateAttr} format="MMM. DD, yyyy" />
                </span>
            </div>
        )
    }
    return '';
}
const ItemDicount = (props) => {
    const user_price = props.product_prices ? (props.product_prices[props.country] ? props.product_prices[props.country] : (props.product_prices['usa'] ? props.product_prices['usa'] : null)) : null;
    let discount = 0;
    if (user_price && user_price > 1 && props.prices.hasOwnProperty('retail_price') && props.prices.retail_price > user_price) {
        discount = Math.round((props.prices.retail_price - user_price) / props.prices.retail_price * 100, 0);
    }
    if (discount && discount > 1) {
        return (
            <span className="release-price-dicount">{discount.toLocaleString() + '% Off'}</span>
        )
    }
    return '';
}
export default ReleaseItem;
