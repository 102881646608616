'use strict';
export default (state = {}, action) => {
  let return_json  = {};
  switch (action.type) {
    case 'LOGIN_FAILURE':
    case 'SIGNUP_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
    case 'CHANGE_PASSWORD_FAILURE':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'CONTACT_FORM_FAILURE':
    case 'OAUTH_FAILURE':
    case 'UNLINK_FAILURE':
    case 'LINK_FAILURE':
    case 'INFLUENCER_CREATE_FAILURE':
    case 'INFLUENCER_UPDATE_FAILURE':
    case 'CHECKOUT_FAILURE':
    case 'RELEASE_LIST_FAILURE':
    case 'ORDER_LIST_FAILURE':
    case 'ORDER_VIEW_FAILURE':
    case 'BUY_NOW_SELECT_SIZE':
    case 'INFLUENCER_DASHBOARD_LOAD_FAILURE':
    case 'IMAGE_TEMP_FAILURE':
    case 'NO_SHIPPING_TO_LOCATION':
    case 'ESTIMATE_TAX_FAILURE':
      if (action.hasOwnProperty('alerts') && action.alerts.length > 0) {
          return_json.error = action.alerts;
      }
      return return_json;
    case 'UPDATE_PROFILE_SUCCESS':
    case 'CHANGE_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
    case 'CONTACT_FORM_SUCCESS':
    case 'CART_ADD_SUCCESS':
    case 'CART_ITEM_QTY_UPDATE':
    case 'INFLUENCER_UPDATE_SUCCESS':
    case 'CART_REMOVE_SUCCESS':
    case 'ORDER_MESSAGES_SEND_SUCCESS':
    case 'INFLUENCER_CREATE_SUCCESS':
      if (action.hasOwnProperty('alerts') && action.alerts.length > 0) {
          return_json.success= action.alerts;
      }
      return return_json;
    case 'FORGOT_PASSWORD_SUCCESS':
    case 'DELETE_ACCOUNT_SUCCESS':
    case 'UNLINK_SUCCESS':
      if (action.hasOwnProperty('alerts') && action.alerts.length > 0) {
          return_json.info= action.alerts;
      }
      return return_json;
    case 'CLEAR_ALERTS':
    case 'ESTIMATE_TAX_LOADING':
      return return_json;
    case 'NETWORK_ERROR':
        return_json.error = [{ 'msg': 'Network connection error, please try again later.' }];
        return return_json;
    case 'RELEASE_VIEW_FAILURE':
        return_json.statusCode = action.statusCode;
      if (action.hasOwnProperty('alerts') && action.alerts.length > 0) {
          return_json.error= action.alerts;
      }
      return return_json;
    default:
      return state;
  }
}
