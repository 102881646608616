'use strict';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux'
import Media from 'react-bootstrap/lib/Media';
import Table from 'react-bootstrap/lib/Table';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Image from 'react-bootstrap/lib/Image';
import { updateQuantity, removeFromCart } from '../../actions/cart';
import { FormatCurrency, httpCorrectUrl } from '../Helper';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = { item: {} };
    this.handleQtyUpdate = this.handleQtyUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount() {
    this.setState({ item: this.props.item }); //, address: address
  }

  componentWillUnmount() {
    this.props.clearAlerts();
  }
  handleQtyUpdate(event) {
    const qty = parseInt(event.value);
    if (Object.keys(this.state).length > 0) {
      let new_cart_item = this.state;
      new_cart_item.item = Object.assign({}, new_cart_item.item, { qty: qty });
      this.setState({ item: new_cart_item.item });
      this.props.updateQuantity(this.props.item, qty);
    }
  }

  handleRemove(nid, event) {
    if (Object.keys(this.props.item).length > 0) {
      this.props.removeFromCart(this.state.item);
    }
  }

  render() {
    if (Object.keys(this.state.item).length == 0) {
      return (
        <div />
      )
    }

    const item = this.state.item;
    let item_total_price = item.qty * (parseFloat(item.product_price.replace(/,/, "")) + parseFloat(item.size_price.replace(/,/, "")));
    const src = httpCorrectUrl(item.release.thumb_image);
    const link = '/' + item.release.slug;
    let description = 'Brand New';
    let shopping_items = (
      <tr>
        <td>
          <Media>
            <Media.Left align="top">
              <Link to={link}>
                <Image width={64} className="cart-image" src={src} alt={item.release.name} />
              </Link>
            </Media.Left>
            <Media.Body>
              <Media.Heading>
                <Link to={link}>{item.release.name}</Link>
              </Media.Heading>
              <HelpBlock>{description}</HelpBlock>
            </Media.Body>
          </Media>
        </td>
        <td>{item.size}</td>
        <td><FormatCurrency value={item_total_price} /></td>
        <td><span className="btn-remove" onClick={this.handleRemove.bind(this, item.nid)} >X</span></td>
      </tr>
    )
    return (
      <div id='shopping-cart'>
        <Table>
          <thead>
            <tr>
              <th><b>Title</b></th>
              <th><b>Size</b></th>
              {/* <th><b>Qty</b></th> */}
              <th><b>Price</b></th>
              <th><b></b></th>
            </tr>
          </thead>
          <tbody>{shopping_items}</tbody>
        </Table>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    item: state.cart.item,
    loading: state.settings.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuantity: (item, qty) => {
      dispatch(updateQuantity(item, qty));
    },
    removeFromCart: (item) => {
      dispatch(removeFromCart(item));
    },
    no_shipping: () => {
      dispatch({
        type: 'NO_SHIPPING_TO_LOCATION',
        alerts: [{ msg: 'We currently do not have a seleced size available to ship to country. Please check back later.' }]
      });
    },
    clearAlerts: (item) => {
      dispatch({
        type: 'CLEAR_ALERTS',
      });
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
