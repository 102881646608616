'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Loader from './Loader';
import PropTypes from 'prop-types';
import SiteMetatags from './SiteMetatags';
import Grid from 'react-bootstrap/lib/Grid';
import Button from 'react-bootstrap/lib/Button';
import ScrollToTop from './ScrollToTop';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import { IntlProvider } from 'react-intl';
import ReactPixel from 'react-facebook-pixel';
// import { CookiesProvider } from 'react-cookie';
// import { loadReCaptcha } from './Helper';
// import { loadReCaptcha } from 'react-recaptcha-v3'

bootstrapUtils.addStyle(Button, 'facebook');
bootstrapUtils.addStyle(Button, 'twitter');
bootstrapUtils.addStyle(Button, 'snkrly');
bootstrapUtils.addStyle(Button, 'invite');

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
    ReactPixel.pageView();
    document.getElementsByTagName('body')[0].classList.remove('silder-fullscreen')
  }
  componentDidUpdate(prevProps, prevState) {
    document.getElementsByTagName('body')[0].classList.remove('silder-fullscreen')
  }

  render() {
    if (this.props.influencer && this.props.influencer.status) {
      return (
        // <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <IntlProvider locale='en'>
            <SiteMetatags />
            {/* <div id="snkrly-wrapper"> */}
              {/* <ShippingInfoBanner country={this.props.country} /> */}
              {/* <div id="top-ad" className='visible-xs' /> */}
              <Header />
              
              <Grid id="snkrly-container">
                {this.props.children}
              </Grid>
              <Footer />
              <ScrollToTop />
              <Loader />
            {/* </div> */}
          </IntlProvider>
        // </CookiesProvider>
      );
    } else {
      return (
          <IntlProvider locale='en'>
            <SiteMetatags />
            <div id="snkrly-wrapper" className="main-page">
              {/* <div id="top-ad" className='visible-xs' /> */}
              <Header />
              <Loader />
              {this.props.children}
              <Footer />
              <ScrollToTop />
            </div>
          </IntlProvider>
      )
    }
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

App.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    routing: state.routing,
    influencer: state.influencer,
    country: state.settings.location ? state.settings.location.snkrly_country_code : '',
    loading: state.settings.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
