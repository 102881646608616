'use strict';

export const addToCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    dispatch({
      type: 'CART_ADD_SUCCESS',
      item: item,
      qty: 1,
      loading: 0,
      alerts: [{ msg: 'Product ' + item.name + ' added to your card.' }]
    });
  };
}
export const updateQuantity = (item, qty) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'CART_ITEM_QTY_UPDATE',
      item: item,
      qty: qty,
      loading: 0,
    });
  };
}

export const removeFromCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    dispatch({
      type: 'CART_REMOVE_SUCCESS',
      item: item,
      loading: 0,
      alerts: [{ msg: 'Product ' + item.name + ' removed from your card.' }]
    });
  };
}
export const emptyToCart = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_ALERTS'
    });
    dispatch({
      type: 'APP_LOADER_LOADING',
    });
    dispatch({
      type: 'CART_EMPTY_SUCCESS',
      item: {},
      qty: 1,
      loading: 0,
      alerts: []
    });
  };
}
